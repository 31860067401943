import React from 'react';
import PropTypes from 'prop-types';

import {  
    Card, 
    CardBody
} from 'reactstrap';

import { FaUntappd } from "react-icons/fa"

const CardCenter = (props) => (
    <Card className="mb-3 mb-lg-0">
       <CardBody>
           <div style={{display: "flex", flexDirection: "column", alignItems: "center", height: "200px"}}>
                <div style={{padding: "5px"}} >{props.icon}</div>
                
                <span className='text-center'>
                    <h1 className="mt-3">
                        { props.content }
                    </h1>
                    <h4 className="mt-1 mb-4">
                        {props.title}
                    </h4>
                </span>
            </div>
       </CardBody>
    </Card>
);

CardCenter.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.element
};
CardCenter.defaultProps = {
    title: "Waiting...",
    content: "0",
    icon: <FaUntappd size="45" />
};

export { CardCenter };
