import React, { useState } from "react";
import {
    Modal, ModalBody,
    Container, Row, Col
} from "reactstrap";
import PropTypes from "prop-types";
import {Terminal} from 'xterm';
import {FitAddon} from 'xterm-addon-fit';
import { Buffer } from 'buffer';
import 'xterm/css/xterm.css';
import { prepareShellQuery, prepareShellStatus, useSocket } from "../contexts/SocketContext";
import { useEffect } from "react";

function ShellModal(props) {
    const { socket } = useSocket();

    const term = new Terminal();
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);

    useEffect(() => {
        let termDocument = document.getElementById('terminal')
        if (termDocument) {
            while (termDocument.children.length) {
                termDocument.removeChild(termDocument.children[0]);
            }
            term.open(termDocument)
            fitAddon.fit();

        }
        window.addEventListener('resize', () => {
            fitAddon.fit();
        })

        if(props.showModal) {
            setTimeout(() => {
                let termDocument = document.getElementById('terminal')
                if (termDocument) {
                    term.open(termDocument, true)
                    fitAddon.fit();
                }
                window.addEventListener('resize', () => {
                    fitAddon.fit();
                })

                /* This works but it works in a way that when the context menu is opened
                it directly paste the clipboard text, it doesn't wait for the paste button.
                termDocument.addEventListener('contextmenu', (e) => {
                    console.log(e)
                    if (term.hasSelection()) {
                      //document.execCommand('copy')
                      //term.select(0, 0, 0)
                    } else {
                        navigator.clipboard.readText().then(text => {
                            socket.sendRequest(prepareShellQuery(props.remoteId, text)).catch(() => {})
                        })
                    }
                  })*/
            }, 1000)
        }

        if (socket !== null && socket.isOpened && props.showModal) {
            socket.sendRequest(prepareShellStatus(props.remoteId, true))
            .then(res => {
                if(res.response.res) {
                    term.write("$ ")
                } else {
                    term.write("\x1b[31;1mShell request failed, try again or check the connection\r\n")
                }
            })
            socket.onMessage.addListener(message => {
                const output = JSON.parse(message)
                if(output.type === "shell") {
                    let termDocument = document.getElementById('terminal')
                    if (termDocument) {
                        term.write(Buffer.from(output.output.data).toString())
                    }
                }
            });
        }

        return () => {
            term.dispose()
            if (socket !== null && socket.isOpened) {
                socket.onMessage.removeAllListeners()
            }
            
        };

    }, [socket, props.showModal]);

    term.attachCustomKeyEventHandler((arg) => {
        if (arg.ctrlKey && arg.code === "KeyV" && arg.type === "keydown") {
            navigator.clipboard.readText()
              .then(text => {
                //term.write(text);
                socket.sendRequest(prepareShellQuery(props.remoteId, text)).catch(() => {})
              })
        };
        return true;
    });

    // Listen on user input, send it to the connection
    term.onKey((e) => {
        //console.log(e)
        const ev = e.domEvent;
        //const printable = !ev.altKey && !ev.ctrlKey && !ev.metaKey;
        if(e.key === "\x7F") { //Backspace
            e.key = "\b \b"
        }
        //console.log(printable)
        /*
        if (ev.keyCode === 8) {
        // Do not delete the prompt
        if (term._core.buffer.x > 2) {
            term.write('\b \b');
        }

        
        
        } else */
         
        //Why did I had this initially? it disabled all the control buttons
         //if (printable) {
            socket.sendRequest(prepareShellQuery(props.remoteId, e.key)).catch(() => {})
        //}
    })

    return (
        <Modal isOpen={props.showModal}
            toggle={() => {
                socket.sendRequest(prepareShellStatus(props.remoteId, false))
                props.changeShowModal()
            }}
            keyboard={false}
            className="modal-outline-dark"
            style={{width: "80vw", minWidth: "80vw"}}>
            <ModalBody className="text-center px-3">
                <Container>
                    <Row>
                        <Col className="text-center py-3">
                            <h2>Interactive Shell</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ backgroundColor: "black", textAlign: "left" }} sm={12}>
                            <div id="terminal"></div>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
        </Modal>
    );
}

ShellModal.propTypes = {
    remoteId: PropTypes.string,
    showModal: PropTypes.bool,
    changeShowModal: PropTypes.func
}

ShellModal.defaultProps = {
    remoteId: 'UNDEFINED',
    showModal: false,
    changeShowModal: () => {}
}

export default ShellModal;