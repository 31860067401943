import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LogoThemed } from './LogoThemed';

const HeaderAuth = (props) => (
    <div className="mb-4">
        <div className="mb-4 text-center">
            <Link to="/">
                {
                    props.icon ? (
                        <i className={ `fa fa-${ props.icon } fa-3x ${ props.iconClassName }` }></i>
                    ) : (
                        <LogoThemed checkBackground height={props.height} newColor="primary" />
                    )
                }
            </Link>
        </div>
        <h5 className="text-center mb-4">
            { props.title }
        </h5>
    </div>
)
HeaderAuth.propTypes = {
    icon: PropTypes.node,
    iconClassName: PropTypes.node,
    title: PropTypes.node,
    text: PropTypes.node,
    height: PropTypes.number
};
HeaderAuth.defaultProps = {
    title: "Waiting for Data...",
    iconClassName: "text-theme",
    height: 60
};

export { HeaderAuth };
