
import React from "react";
import { Route, Redirect } from "react-router-dom";
import {Buffer} from 'buffer';

function PrivateRoute({ component: Component, ...rest }) {
  const existingToken = localStorage.getItem("wsToken");

  function checkAuth() {
    if(existingToken !== undefined && existingToken !== null && getParsedJwtToken(existingToken)) {
        return true;
    }
    else {
      return false;
    }
  }
  return (
    <Route
      {...rest}
      render={props =>
        checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/pages/login" />
        )
      }
    />
  );
}

/**
 * Returns a JS object representation of a Javascript Web Token from it's common encoded
 * string form.
 *
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(boolean)} true if the token is not expired, false otherwise
 * or undefined if parsing failed
 */
 export function getParsedJwtToken(token) {
  try {
    let tokenObj = JSON.parse(atob(token.replace(/['"]+/g, '').split('.')[1]));
    if (Date.now() >= tokenObj.exp * 1000) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
}

const atob = str => Buffer.from(str, 'base64').toString('binary');

export default PrivateRoute;