import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ThemeConsumer } from '../Theme';

const logos = {
    'dark': require('./../../images/logos/dark.png'),
    'white': require('./../../images/logos/light.png'),
    'danger': require('./../../images/logos/light.png'),
    'primary': require('./../../images/logos/dark.png')
}

const getLogoUrl = (style, color) => {
    return logos[color];
}

// Check for background
const getLogoUrlBackground = (style, color) => {
    if (style === 'color') {
        return logos['white'];
    } else {
        return getLogoUrl(style, color);
    }
}

const LogoThemed = ({ checkBackground, className, newColor, ...otherProps }) => (
    <ThemeConsumer>
    {
        ({ style, color }) => (
            <img
                src={
                    checkBackground ?
                        getLogoUrlBackground(style, newColor ? newColor : color) :
                        getLogoUrl(style, newColor ? newColor : color)
                }
                className={ classNames('d-inline-block', className) }
                style={{width: "100%", minWidth: "125px", padding: 0}}
                alt="Specular Logo"
                { ...otherProps }
            />
        )
    }
    </ThemeConsumer>
);
LogoThemed.propTypes = {
    checkBackground: PropTypes.bool,
    className: PropTypes.string,
    newColor: PropTypes.string
};

export { LogoThemed };
