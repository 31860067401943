import React, { useState, useEffect, useContext, forwardRef } from 'react';
import {
    Container,
    Row,
    Col,
    CardBody,
    Media,
    Badge,
    Card,
    DropdownItem,
    UncontrolledButtonDropdown,
    DropdownToggle,
    ButtonToolbar,
    DropdownMenu,
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ButtonDropdown,
    UncontrolledDropdown
} from 'reactstrap';
import { HeaderMain } from "../components/HeaderMain";
import _, { filter } from "lodash";
import { prepareKpiServiceQuery, prepareSystemServiceQuery, useSocket } from '../contexts/SocketContext';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment/moment';
import { Button, ButtonGroup, Dropdown } from '../components';
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/flatpickr.css";
import ReactDatePicker from 'react-datepicker';
import { FaArrowRight, FaCalendar, FaCalendarAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';



function MirrorKPIs() {
    const { socket } = useSocket();
    const [historicalData, setHistoricalData] = useState([]);
    const [xTickInterval, setXTickInterval] = useState("day")
    const [availableApplications, setAvailableApplications] = useState([])

    const [dateLabel, setDateLabel] = useState("Realtime");
    const [startDate, setStartDate] = useState(() => {
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        console.log(oneMonthAgo)
        return oneMonthAgo
    });
    const [endDate, setEndDate] = useState(new Date());

    const [filterArr, setFilterArr] = useState([{ application: "Restaurant", action: "Click" }])


    useEffect(() => {
        refreshValues();
    }, [socket])

    function refreshValues() {
        if (socket !== null && socket.isOpened) {
            const tickInterval =  getXTickInterval([{ date: startDate }, { date: endDate }])
            setXTickInterval(tickInterval)
            console.log(tickInterval)
            socket.sendRequest(prepareSystemServiceQuery("GET", "applications")).then(res => {
                setAvailableApplications(res.response)
            })
            socket.sendRequest(prepareKpiServiceQuery("GetCounterAggregation",
                {
                    startTime: startDate,
                    endTime: endDate,
                    application: filterArr.map(item => ({name: item.application.toLocaleLowerCase(), action: item.action.toLocaleLowerCase()})),
                    aggregateBy: ["sum"],
                    groupBy: tickInterval !== "millisecond" ? tickInterval : "day"
                }))
            .then(res => {
                console.log(res)
                var timeseries = res.response.res.map(item => ({
                    ...item,
                    date: moment({ ...item._id.date, month: item._id.date.month - 1 }).toDate()
                }))

                const allDates = Array.from(
                    { length: Math.ceil((endDate - startDate) / (24 * 60 * 60 * 1000)) },
                    (_, index) => {
                      const date = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
                      switch (tickInterval) {
                        case 'hour':
                          for (let i = 0; i < 24; i++) {
                            const newDate = new Date(date);
                            newDate.setHours(i);
                            if (newDate >= startDate && newDate <= endDate) {
                              return {_id: {action: filterArr[0].action.toLocaleLowerCase(), application: filterArr[0].application.toLocaleLowerCase()}, sum: 0, date: newDate };
                            }
                          }
                          break;
                        case 'minute':
                          for (let i = 0; i < 24 * 60; i++) {
                            const newDate = new Date(date);
                            newDate.setHours(Math.floor(i / 60));
                            newDate.setMinutes(i % 60);
                            if (newDate >= startDate && newDate <= endDate) {
                                return {_id: {action: filterArr[0].action.toLocaleLowerCase(), application: filterArr[0].application.toLocaleLowerCase()}, sum: 0, date: newDate};
                            }
                          }
                          break;
                        default: // 'day'
                          return {_id: {action: filterArr[0].action.toLocaleLowerCase(), application: filterArr[0].application.toLocaleLowerCase()}, sum: 0, date: date};
                      }
                    }
                  );
                  

                  const missingDates = allDates.filter(date => !timeseries.some(d => { return moment(d.date).toDate().getTime() === date.date.getTime()}));
                  
                  const updatedDates = [...timeseries.map(date => {
                    console.log(date)
                    //date.date.setHours(0);
                    //date.date.setMinutes(0);
                    date.date.setSeconds(0);
                    date.date.setMilliseconds(0);
                    console.log(date)
                    return date;
                  }), ...missingDates].sort((a, b) => a.date - b.date);
                  
                  console.log(updatedDates);

                setHistoricalData(updatedDates.map(item => ({
                    ...item,
                    [`${item._id.application}-${item._id.action}`]: item.sum
                })))

                console.log(updatedDates.map(item => ({
                    ...item,
                    [`${item._id.application}-${item._id.action}`]: item.sum
                })))
            })
        }
    }

    const CustomDatePicker = forwardRef(({ value, onClick, label }, ref) => {
        return (
            <UncontrolledDropdown ref={ref} onClick={onClick} active={false} addonType="append">
                <DropdownToggle outline={"true"} className='form-control' tag="input" defaultValue={label} children={null} disabled={true} />
            </UncontrolledDropdown>
        )
    });

    //Handle all input changes in input arrays
    function handleChangeInput(i, event, array, setMethod) {
        const values = [...array];
        const { name, value } = event.target;
        values[i][name] = value;
        setMethod(values);
    }

    //Add an input to input array
    function handleAddInput(array, setMethod) {
        const values = [...array];
        values.push({ application: "Choose Application", action: "Click" });
        setMethod(values);
    }

    //Remove an input from input array
    function handleRemoveInput(index, array, setMethod) {
        const values = [...array];
        values.splice(index, 1);
        setMethod(values);
    }

    const getXTickInterval = (data) => {
        const timeRange = data[data.length - 1].date - data[0].date;

        const oneDay = 1000 * 60 * 60 * 24;
        const oneHour = 1000 * 60 * 60;
        const oneMinute = 1000 * 60;
        const granularity = {
            year: 365 * oneDay,
            quarter: 3 * 31 * oneDay,
            month: 31 * oneDay,
            week: 7 * oneDay,
            day: oneDay,
            hour: oneHour,
            minute: oneMinute,
            second: 1000,
        };
        for (const [interval, duration] of Object.entries(granularity)) {
            if (timeRange / duration >= 1) {
                return interval;
            }
        }
        return 'millisecond';
    };
    const formatTimeTick = (tickValue) => {
        const date = new Date(tickValue);
        if (date === "auto" || date === 0) {
            return Date.now()
        }
        //const xTickInterval = getXTickInterval(data);
        switch (xTickInterval) {
            case 'year':
                return date.getFullYear();
            case 'quarter':
            case 'month':
                return `${date.getDate()}/${date.toLocaleString('default', { month: '2-digit' })}`;
            case 'week':
            case 'day':
                return `${date.getDate()}/${date.toLocaleString('default', { month: '2-digit' })} ${date.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' })}`;
            case 'hour':
                return date.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' });
            case 'minute':
                return date.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' });
            default:
                return '';
        }
    };

    return (
        <div style={{ width: "100%", height: 300 }}>
            <Container>
                <Row className="mb-5">
                    <Col lg={12}>
                        <HeaderMain
                            title="Statistics"
                            className="mb-3"
                        />
                    </Col>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Media>
                                    <Media body>
                                        <Col sm={12}>
                                            <div style={{ display: "flex" }}>
                                                <InputGroup>
                                                    <InputGroupAddon addonType='prepend'>
                                                        <InputGroupText><FaCalendarAlt />&nbsp;Select Date Range</InputGroupText>
                                                    </InputGroupAddon>
                                                    <UncontrolledButtonDropdown addonType="append">
                                                        <DropdownToggle outline={true}>{dateLabel}</DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem header>
                                                                Select Period:
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => setDateLabel("Realtime")} active={dateLabel === "Realtime"}>
                                                                Realtime
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => setDateLabel("Last Month")} active={dateLabel === "Last Month"}>
                                                                Last Month
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => setDateLabel("Last 3 Months")} active={dateLabel === "Last 3 Months"}>
                                                                Last 3 Months
                                                            </DropdownItem>
                                                            <DropdownItem divider />
                                                            <DropdownItem onClick={(e) => { setDateLabel(e.currentTarget.textContent) }}>
                                                                Custom Range
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                    <div>
                                                        <ReactDatePicker
                                                            selected={startDate}
                                                            onChange={e => setStartDate(e)}
                                                            //showTimeSelect
                                                            customInput={<CustomDatePicker label={`${dateLabel !== "Custom Range" ? dateLabel : startDate.toLocaleDateString("he-IL", { hour: "2-digit", minute: "2-digit" })}`} />}
                                                            dateFormat="MMMM d, yyyy HH:mm"
                                                            timeInputLabel="Time:"
                                                            showTimeInput
                                                            disabled={dateLabel !== "Custom Range"}
                                                        />
                                                    </div>
                                                    <InputGroupAddon addonType="append">
                                                        <InputGroupText><FaArrowRight /></InputGroupText>
                                                    </InputGroupAddon>
                                                    <div>
                                                        <ReactDatePicker
                                                            selected={startDate}
                                                            onChange={e => setEndDate(e)}
                                                            //showTimeSelect
                                                            customInput={<CustomDatePicker label={`${dateLabel !== "Custom Range" ? "Now" : endDate.toLocaleDateString("he-IL", { hour: "2-digit", minute: "2-digit" })}`} />}
                                                            dateFormat="MMMM d, yyyy HH:mm"
                                                            timeInputLabel="Time:"
                                                            showTimeInput
                                                            disabled={dateLabel !== "Custom Range"}
                                                        />
                                                    </div>
                                                </InputGroup>
                                                <ButtonGroup size="md">
                                                    <Button color="primary" outline={false} onClick={() => {refreshValues() }}>
                                                        Apply
                                                    </Button>
                                                    <Button
                                                        color="info"
                                                        outline={false}
                                                        onClick={() => { /* resetFilters */ }}
                                                    >
                                                        Reset
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        </Col>



                                        <Col sm={12}>
                                            {
                                                filterArr.map((entry, index) => (
                                                    <InputGroup key={`${entry}-${index}`} size="sm">

                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>Filter</InputGroupText>
                                                        </InputGroupAddon>
                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>Select Application</InputGroupText>
                                                        </InputGroupAddon>
                                                        <UncontrolledButtonDropdown addonType="append">
                                                            <DropdownToggle outline={true}>{entry.application}</DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem header>
                                                                    Application
                                                                </DropdownItem>
                                                                {
                                                                    availableApplications.map(app => (
                                                                        <DropdownItem name="application" value={app.internalId.split("specular_")[1]} onClick={e => { handleChangeInput(index, e, filterArr, setFilterArr) }}>
                                                                            {app.title}
                                                                        </DropdownItem>
                                                                    ))
                                                                }
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>

                                                        <InputGroupAddon addonType="append">
                                                            <InputGroupText>Action</InputGroupText>
                                                        </InputGroupAddon>
                                                        <UncontrolledButtonDropdown addonType="append">
                                                            <DropdownToggle outline={true}>{entry.action}</DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem header>
                                                                    Action
                                                                </DropdownItem>
                                                                <DropdownItem name="action" value={"Click"} onClick={e => { handleChangeInput(index, e, filterArr, setFilterArr) }}>
                                                                    Click
                                                                </DropdownItem>
                                                                <DropdownItem name="action" value={"Order"} onClick={e => { handleChangeInput(index, e, filterArr, setFilterArr) }}>
                                                                    Order
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                        {filterArr.length !== 1 &&
                                                            <InputGroupAddon addonType="append">
                                                                <Button color="danger" outline={false} onClick={() => { handleRemoveInput(index, filterArr, setFilterArr) }}>
                                                                    <i className="fa fa-remove"></i>
                                                                </Button>
                                                            </InputGroupAddon>}
                                                        {filterArr.length - 1 === index &&
                                                            <InputGroupAddon addonType="append">
                                                                <Button color="success" outline={false} onClick={() => { handleAddInput(filterArr, setFilterArr) }}>
                                                                    <i className="fa fa-plus"></i>
                                                                </Button>
                                                            </InputGroupAddon>
                                                        }

                                                    </InputGroup>
                                                ))}
                                        </Col>
                                    </Media>
                                </Media>

                                <ResponsiveContainer height={400}>
                                    <LineChart
                                        data={historicalData}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0
                                        }}
                                    >
                                        <CartesianGrid  />
                                        <XAxis dataKey="date" allowDuplicatedCategory={false} tickFormatter={formatTimeTick}  />
                                        <YAxis dataKey="sum" />
                                        <Legend />
                                        <Tooltip />
                                        {
                                            filterArr.map(item => [(
                                                <Line type="monotone" dataKey={`${item.application.toLocaleLowerCase()}-${item.action.toLocaleLowerCase()}`} stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`} />
                                            )])
                                        }
                                        
                                    </LineChart>
                                </ResponsiveContainer>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default MirrorKPIs;