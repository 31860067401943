import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    Input as RSInput
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';

function EditVersions(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [version, setVersion] = useState("")
    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState("")
    const [caption, setCaption] = useState("")

    useEffect(() => {
        setDataLoading(false)
    }, [socket]);

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }


        const formData = new FormData();
        let headers = new Headers();

		formData.append('file', file);
        formData.append("version", version);
        formData.append("caption", caption);

        headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);

		fetch(`${localStorage.getItem("serverIp")}/version`, {
                    method: 'POST',
                    headers: headers,
                    body: formData,
                }
            )
			.then((response) => response.json())
			.then((res) => {
                if(res.success) {
                    toast.success(contentSuccess);
                    setOperation(true);
                } else {
                    setMessage(res.message)
                }
			})
			.catch((error) => {
				setMessage(error)
			});
    }

    function addFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name)
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/versions" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Add New Version"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="title" id="titleLabel" sm={3}>
                                        Title <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="title" maxLength="52" value={caption} onChange={e => setCaption(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Version <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={version} onChange={e => setVersion(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="package" id="packageLabel" sm={3}>
                                        Package
                                    </Label>
                                    <Col sm={9}>
                                    <RSInput
                                        bsSize='sm'
                                        type="file"
                                        label={fileName || 'choose an image file'}
                                        onChange={addFile}
                                        modifiers={{
                                            setMaxHeight: {
                                                enabled: true,
                                                order: 890,
                                                fn: (data) => {
                                                    return {
                                                        ...data,
                                                        styles: {
                                                            ...data.styles,
                                                            overflow: 'auto',
                                                            maxHeight: '200px',
                                                        },
                                                    };
                                                }
                                            }
                                        }} />
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

EditVersions.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditVersions;

