import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, Switch } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';
import ReactSelect from '../../components/AdvancedSelect'

function EditAdvertisement(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [layouts, setLayouts] = useState([{value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3}, {value: 4, label: 4}, {value: 5, label: 5}, {value: 6, label: 6}, {value: 7, label: 7}, {value: 8, label: 8}, {value: 9, label: 9}, {value: 10, label: 10},])
    const [branches, setBranches] = useState([])

    const [title, setTitle] = useState("")
    const [layout, setLayout] = useState({value: 1, label: 1})
    const [selectedBranch, setSelectedBranch] = useState([])
    const [text, setText] = useState("")
    const [url, setUrl] = useState("")
    const [fromTime, setFromTime] = useState(new Date())
    const [toTime, setToTime] = useState(new Date())
    const [isEnabled, setIsEnabled] = useState(true)
    const [image, setImage] = useState("")

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "advertise"))
            ])
            .then(([branchesInfo, res]) => {
                setBranches(branchesInfo.response)

                res.response.forEach(item => {
                    if(item._id === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        console.log(item)
                        if(item.layout) {
                            setLayout({value: item.layout, label: item.layout})
                            setLayout(item.layout.map(services => ({value: services, label: services})))
                            
                        }
                        if(item.branch) {
                            setSelectedBranch(item.branch)
                        }
                        setTitle(item.title)
                        setText(item.text)
                        setUrl(item.url)
                        setFromTime(new Date(Date.parse(item.fromTime)))
                        setToTime(new Date(Date.parse(item.toTime)))
                        setIsEnabled(item.isEnabled)
                        setImage(item.image)
                        
                        setDataLoading(false)
                    }
                })
            })
            .then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            layout: layout.map(layout => layout.value),
            branch: selectedBranch,
            title: title,
            text: text,
            url: url,
            fromTime: fromTime.toISOString(),
            toTime: toTime.toISOString(),
            isEnabled: isEnabled,
            image: image
        };
        console.log(finalRule)

        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "advertise", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("POST", "advertise", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/specular/ads" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title={(isEdit ? "Edit" : "Add") + " an advertisement"}
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="hotel" id="hotelLabel" sm={3}>
                                        Set at which branch the applications will be visible
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={selectedBranch}>
                                            {
                                                branches.map(availableHotel => (
                                                    <DropdownItem key={availableHotel._id} onClick={(e) => setSelectedBranch(availableHotel._id)}>
                                                        {availableHotel.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="selectedLayout" id="selectedLayout" sm={3}>
                                        Mirror Selected Layout
                                    </Label>
                                    <Col sm={9}>
                                        <ReactSelect
                                            value={layout} 
                                            onChange={e => {console.log(e); setLayout(e)}} 
                                            options={layouts}
                                            isMulti={true}
                                            />
                                            
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="title" id="titleLabel" sm={3}>
                                        Title
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="title" value={title} onChange={e => setTitle(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="text" id="textLabel" sm={3}>
                                        Description
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="textarea" id="text" value={text} onChange={e => setText(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="image" id="imageLabel" sm={3}>
                                        Image URL
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="image" value={image} onChange={e => setImage(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="url" id="urlLabel" sm={3}>
                                        Referal URL
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="url" value={url} onChange={e => setUrl(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="isEnabled" id="isEnabledLabel" sm={3}>
                                        Is the ad enabled?
                                    </Label>
                                    <Col sm={9}>
                                        <Switch value={isEnabled} setValue={setIsEnabled} />
                                    </Col>
                                </FormRow>
                                </Form>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            Pick Times
                        </CardHeader>
                        <CardBody>
                            <Form>

                            </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>
        </Container>
    );
}

EditAdvertisement.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditAdvertisement;

