import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup, Switch } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';
import HelpPopup from "../../components/helpPopup"
import ReactSelect from 'react-select';

import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';
/*
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
//import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
*/
function EditApplication(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [branches, setBranches] = useState([])
    const [availableTypes, setAvailableTypes] = useState([])

    const [type, setType] = useState("")
    const [title, setTitle] = useState("")
    const [selectedBranches, setSelectedBranches] = useState([])

    const [appValue, setAppValue] = useState({})
    const [contentEditor, setContentEditor] = useState("<p>This is the initial content of the editor</p>");
    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
        setContentEditor(content);
    }
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
        console.log(editorRef.current.getContent());
        }
    };

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch/applications")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "applications")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch"))
            ]).then(([branchApps, allApps, branchesInfo]) => {
                console.log(branchApps)
                branchApps.response.forEach(item => {
                    if(item._id === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        setTitle(item.title)
                        setType(item.type.title)
                        setSelectedBranches(item.branch.map(branch => ({value: branch._id, label: branch.name})))
                        
                        switch(item.type.title) {
                            case "Paragraph": {
                                setContentEditor(item.customizedObjects.labelValue)
                                break;
                            }
                            case "Browser": {
                                setAppValue(item.customizedObjects)
                                break;
                            }
                            default: {
                                if(item.customizedObjects) {
                                    var newAppValue = item.customizedObjects
                                    for (let index = 0; index < Object.keys(newAppValue).length; index++) {
                                        var value = newAppValue[Object.keys(newAppValue)[index]]
                                        if(value === true || value === false) {
                                            value = value + ""
                                        } else if(value.length > 0 || Object.keys(value).length > 0) {
                                            value = JSON.stringify(value)
                                        }
                                        
                                        newAppValue[Object.keys(newAppValue)[index]] = value
                                    }
                                    setAppValue(newAppValue)
                                }
                                break;
                            }
                        }

                        setDataLoading(false)
                    }
                })
                setAvailableTypes(allApps.response.map(item => ({...item, appParams: item.appParams.map(params => ({
                    ...params,
                    paramName: params.paramType === "arr" ? JSON.stringify(params.paramName) : params.paramName + ""
                }))})))
                setBranches(branchesInfo.response.map(branch => ({value: branch._id, label: branch.name})))
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            title: title,
            branch: selectedBranches.map(branch => branch.value),
            type: availableTypes.filter(allTypes => allTypes.title === type)[0],
            x: 0,
            y: 0,
            isEnabled: true,
            customizedObjects: {
                
            }
        };

        switch(type) {
            case "Paragraph": {
                finalRule.customizedObjects.labelValue = contentEditor
                break;
            }
            case "Browser": {
                finalRule.customizedObjects = appValue
                break;
            }
            default: {
                var newAppValue = appValue
                for (let index = 0; index < Object.keys(newAppValue).length; index++) {
                    var value = newAppValue[Object.keys(newAppValue)[index]]
                    
                    if(typeof value === Boolean || typeof value === Array) {

                    } else if(value === "true" || value === "false") {
                        value = value === "true"
                    }
                    else if((value.startsWith('[') || value.startsWith('{')) && (value.endsWith(']') || value.endsWith('}'))) {
                        value = JSON.parse(value)
                    }
                    
                    newAppValue[Object.keys(newAppValue)[index]] = value
                }
                finalRule.customizedObjects = newAppValue
                break;
            }
        }

        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "branch/applications", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("PUT", "branch/applications", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/specular/apps" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title={(isEdit ? "Edit" : "Add") + " an application"}
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="title" id="titleLabel" sm={3}>
                                        Title
                                    </Label>
                                    <HelpPopup target="titleLabel" header="Title">
                                        Identifier for you to manage the application
                                    </HelpPopup>
                                    <Col sm={9}>
                                        <Input type="text" id="title" value={title} onChange={e => setTitle(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                
                                <FormRow>
                                    <Label for="branches" id="branchLabel" sm={3}>
                                        Branches
                                    </Label>
                                    <HelpPopup target="branchLabel" header="Branches">
                                        Set the branches that will use this customization
                                    </HelpPopup>
                                    <Col sm={9}>
                                        <ReactSelect
                                            value={selectedBranches} 
                                            isMulti 
                                            onChange={e => {console.log(e); setSelectedBranches(e)}} 
                                            options={branches}
                                            styles={{
                                                control: (provided, state) => ({
                                                  ...provided,
                                                  background: '#F9FAFC',
                                                  borderColor: '#DEE2E6',
                                                  minHeight: '30px',
                                                  height: '30px',
                                                  boxShadow: state.isFocused ? null : null,
                                                }),
                                            
                                                valueContainer: (provided, state) => ({
                                                  ...provided,
                                                  height: '30px',
                                                  padding: '0 6px'
                                                }),
                                            
                                                input: (provided, state) => ({
                                                  ...provided,
                                                  margin: '0px',
                                                }),
                                                indicatorSeparator: state => ({
                                                  display: 'none',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                  ...provided,
                                                  height: '30px',
                                                }),
                                                multiValue: (provided, state) => ({
                                                    ...provided,
                                                    height: '18px',
                                                  }),
                                                  multiValueLabel: (provided, state) => ({
                                                      ...provided,
                                                      fontSize: 13,
                                                      alignItems: "center",
                                                      display: "flex"
                                                    })
                                              }}
                                            />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="type" id="typeLabel" sm={3}>
                                        Type
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={type} menuoptions={{
                                            modifiers: {
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                      return {
                                                        ...data,
                                                        styles: {
                                                          ...data.styles,
                                                          overflow: 'auto',
                                                          maxHeight: 300,
                                                        },
                                                      };
                                                    }}
                                            }
                                        }}>
                                            {
                                                availableTypes.map(availableType => (
                                                    <DropdownItem key={availableType._id} onClick={(e) => setType(availableType.title)}>
                                                        {availableType.title}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    {
                        type === "Paragraph" &&
                        <Editor
                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                                'advlist', 'autolink', 'lists'/*, 'link'*/, 'image', 'charmap',
                                'visualblocks', 'visualchars', 'fullscreen', 'emoticons',
                                'help', 'wordcount', 'directionality', 'formatpainter',  'code'
                            ],
                            valid_children : '+body[style]',
                            toolbar: 'undo redo | blocks fontfamily fontsize | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | ' +
                                'ltr rtl | emoticons | ' +
                                'bullist numlist lineheight | ' +
                                'removeformat | code | help',
                                font_family_formats: "Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Sans-Serif;Comic Sans MS;Curier New;Georgia;Helvetica;Impact;Monospace;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana;Webdings;Wingdings",
                                content_style: 'body { font-family:Calibri,Helvetica,Arial,sans-serif; font-size:16px }'
                            }}
                            value={contentEditor}
                            onEditorChange={handleEditorChange}
                        />
                    }
                    {
                        type === "Browser" &&
                        <Card className="mb-3">
                            <CardHeader>
                                Browser Properties
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <FormRow>
                                        <Label for="url" id="urlLabel" sm={3}>
                                            URL
                                        </Label>
                                        <HelpPopup target="urlLabel" header="URL">
                                            What URL would the browser default to
                                        </HelpPopup>
                                        <Col sm={9}>
                                            <Input type="text" id="url" value={appValue.url ? appValue.url : ""} onChange={e => setAppValue({...appValue, url: e.target.value})} required />
                                        </Col>
                                    </FormRow>
                                </Form>
                            </CardBody>
                        </Card>
                    }
                    {
                        type !== "" && type !== "Browser" && type !== "Paragraph" &&
                        <Card className="mb-3">
                            <CardHeader>
                                Available Parameters
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    {
                                        availableTypes.filter(item => item.title === type)[0].appParams.map(item => (
                                            <FormRow key={item.label}>
                                                <Label sm={3}>
                                                    {item.label}
                                                </Label>
                                                <Col sm={9}>
                                                {
                                                    (item.paramType === "string" || item.paramType === "array") &&
                                                    <Input type="text" name="paramName" value={appValue[item.label]} onChange={e => setAppValue({...appValue, [item.label]: e.target.value})} />
                                                }
                                                {
                                                    (item.paramType === "bool") &&
                                                    <Dropdown toggle={appValue[item.label]}>
                                                        <DropdownItem name="paramName" value={true} onClick={(e) => setAppValue({...appValue, [item.label]: e.target.value})}>True</DropdownItem>
                                                        <DropdownItem name="paramName" value={false} onClick={(e) => setAppValue({...appValue, [item.label]: e.target.value})}>False</DropdownItem>
                                                    </Dropdown>
                                                }
                                                </Col>
                                            </FormRow>
                                        ))
                                    }
                                </Form>
                            </CardBody>
                        </Card>
                    }
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px", marginTop: "10px", marginBottom: "10px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                    {
                        type === "Paragraph" &&
                        <div style={{ backgroundColor: "#474747", border: `2px solid grey`, borderRadius: "12px", position: "absolute"}} dangerouslySetInnerHTML={{__html: `<div style="color: white; margin: 10px">${contentEditor}</div>`}}></div>

                    }
                </Col>
            </Row>
        </Container>
    );
}

EditApplication.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditApplication;

