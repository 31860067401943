import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader, DropdownItem
} from 'reactstrap';

import { Button, FormRow, Input, Dropdown } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';
import HelpPopup from '../../components/helpPopup';

function UserRegister(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    //const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [companies, setCompanies] = useState([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [language, setLanguage] = useState("עברית")

    const [availableCompanies, setAvailableCompanies] = useState([]);

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")).then(res => {
                setAvailableCompanies(res.response);
            })
            socket.sendRequest(prepareSystemServiceQuery("GET", "user")).then(res => {
                res.response.forEach(item => {
                    if(item._id === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        //setUsername(item.username);
                        setEmail(item.email);
                        setCompanies(item.company.map(item => item.name));
                        setLanguage(item.language);
                        //setIsAdmin(item.admin);

                        setDataLoading(false)
                    }
                })
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            //username: username,
            email: email,
            companies: companies,
            isAdmin: isAdmin,
            language: language
        };

        if(password !== "") {
            finalRule.password = password;
        }

        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "user", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("POST", "user", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/users" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Add a User"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="email" id="emailLabel" sm={3}>
                                        Email
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="email" maxLength="52" value={email} onChange={e => setEmail(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="password" id="passwordLabel" sm={3}>
                                        Password
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="password" id="password" maxLength="52" value={password} onChange={e => setPassword(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="language" id="languageLabel" sm={3}>
                                        Language
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={language}>
                                            <DropdownItem onClick={(e) => setLanguage(e.currentTarget.textContent)}>
                                                English
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setLanguage(e.currentTarget.textContent)}>
                                                עברית
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            Companies
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="companies" id="companiesLabel" sm={3}>
                                        Select Companies
                                    </Label>
                                    <HelpPopup header="Companies" target="companiesLabel">
                                        The companies this user will belong to, will affect visibility of menus.
                                    </HelpPopup>
                                    <Col sm={9}>
                                        <Input
                                            type="select"
                                            name="companies"
                                            id="companies"
                                            value={companies}
                                            onChange={e => setMultiple(e, setCompanies)}
                                            multiple
                                            style={{ height: "150px" }}
                                        >
                                            {
                                                availableCompanies.map(rule => (
                                                    <option value={rule.name} key={rule.name}>
                                                        {rule.name}
                                                    </option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

UserRegister.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default UserRegister;

