import { createContext, useContext } from 'react';

export const SocketContext = createContext();
export function useSocket() {
  return useContext(SocketContext);
}

/**
 * This method prepares a JSON object that can be used within socket communication.
 * This also uses the selected service to query the specific device.
 * @param {*} endpoint URI to use within F5
 * @param {*} method REST methods supported: GET, POST, PUT, DELETE
 * @param {*} data if a body is needed to query with F5
 * @returns a JSON object that can be used to communicate with the backend WebSocket connection
 */
 export function preparePfsenseServiceQuery(method, uri, data) {
  var returnObject = {
    request_type: "ServiceQuery",
    action: "pfsense",
    method: method,
    uri: uri
  }
  if (data) {
    returnObject.data = data;
  }

  const selectedService = localStorage.getItem("selectedService");
  const applicationState = localStorage.getItem("applicationState");
  if (selectedService !== undefined && selectedService !== null) {
    if (applicationState !== undefined && applicationState !== null && applicationState === "GBOX") {
      returnObject.remote = selectedService;
      returnObject.requested_service = "default";
    } else {
      returnObject.requested_service = selectedService;
    }
  } else {
    returnObject.requested_service = "default"
  }
  
  return returnObject;
}

/**
 * This method prepares a JSON object that can be used within socket communication.
 * This also uses the selected service to query the specific device.
 * @param {*} endpoint URI to use within F5
 * @param {*} method REST methods supported: GET, POST, PUT, DELETE
 * @param {*} data if a body is needed to query with F5
 * @returns a JSON object that can be used to communicate with the backend WebSocket connection
 */
export function prepareF5ServiceQuery(method, uri, data) {
  var returnObject = {
    request_type: "ServiceQuery",
    action: "f5",
    method: method,
    uri: uri
  }
  if (data) {
    returnObject.data = data;
  }

  const selectedService = localStorage.getItem("selectedService");
  if (selectedService !== undefined && selectedService !== null) {
    returnObject.requested_service = selectedService;
  } else {
    returnObject.requested_service = "default"
  }

  return returnObject;
}

/**
 * This method prepares a JSON object that can be used within socket communication.
 * This also uses the selected service to query the specific device.
 * @param {*} endpoint URI to use within Bezeq Wholesale
 * @param {*} method REST methods supported: GET, POST, PUT, DELETE
 * @param {*} data if a body is needed to query with Wholesale
 * @returns a JSON object that can be used to communicate with the backend WebSocket connection
 */
 export function prepareWholesaleServiceQuery(uri, data) {
  var returnObject = {
    request_type: "ServiceQuery",
    action: "wholesale",
    uri: uri
  }
  if (data) {
    returnObject.data = data;
  }

  const selectedService = localStorage.getItem("selectedService");
  if (selectedService !== undefined && selectedService !== null) {
    returnObject.requested_service = selectedService;
  } else {
    returnObject.requested_service = "default"
  }

  return returnObject;
}

export function prepareApaleoServiceQuery(method, uri, data) {
  var returnObject = {
    request_type: "ServiceQuery",
    action: "ApaleoPMS",
    method: method,
    uri: uri
  }
  if (data) {
    returnObject.data = data;
  }

  const selectedService = localStorage.getItem("selectedService");
  if (selectedService !== undefined && selectedService !== null) {
    returnObject.requested_service = selectedService;
  } else {
    returnObject.requested_service = "default"
  }

  return returnObject;
}

export function prepareKpiServiceQuery(uri, data) {
  var returnObject = {
    request_type: "ServiceQuery",
    action: "timeseries",
    uri: uri
  }
  if (data) {
    returnObject.data = data;
  }

  return returnObject;
}


/**
 * This method prepares a JSON object that can be used within socket communication
 * @param {*} endpoint URI to use within Node server
 * @param {*} method REST methods supported: GET, POST, PUT, DELETE
 * @param {*} data if a body is needed to query with Pfsense
 * @returns a JSON object that can be used to communicate with the backend WebSocket connection
 */
export function prepareSystemServiceQuery(method, uri, data) {
  var returnObject = {
    request_type: "System",
    method: method,
    uri: uri
  }
  if (data) {
    returnObject.data = data;
  }

  return returnObject;
}

/**
 * This method prepares a JSON object that can be used within socket communication
 * @param {*} serviceId Application service ID to be authenticate OAuth2.0 service with
 * @param {*} accessCode code that was given from OAuth redirect to be exchanged for access_tokens
 * @returns oAuthStatus - boolean
 */
 export function prepareOAuthAccessQuery(serviceId, accessCode) {
  var returnObject = {
    request_type: "OAuthAccess",
    serviceId: serviceId,
    accessCode: accessCode
  }

  return returnObject;
}

/**
 * This method prepares a JSON object that can be used within socket communication
 * @param {*} endpoint URI to use within Node server
 * @param {*} method REST methods supported: GET, POST, PUT, DELETE
 * @param {*} data if a body is needed to query with Pfsense
 * @returns a JSON object that can be used to communicate with the backend WebSocket connection
 */
export function prepareShellQuery(remote, data) {
  var returnObject = {
    request_type: "InteractShell",
    remote: remote,
    command: data
  }

  return returnObject;
}

/**
 * This method prepares a JSON object that can be used within socket communication
 * @param {*} endpoint URI to use within Node server
 * @param {*} method REST methods supported: GET, POST, PUT, DELETE
 * @param {*} data if a body is needed to query with Pfsense
 * @returns a JSON object that can be used to communicate with the backend WebSocket connection
 */
export function prepareShellStatus(remote, status) {
  var returnObject = {
    request_type: "InitiateShell",
    remote: remote,
    status: status
  }

  return returnObject;
}