import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from './ThemeContext';

export class ThemeProvider extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        initialStyle: PropTypes.string,
        initialColor: PropTypes.string,
        dir: PropTypes.oneOf(['rtl', 'ltr']),
        lang: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            style: 'light',
            color: 'danger',
            dir: 'ltr',
            lang: 'en'
        };

    }

    componentDidMount() {
        const { initialStyle, initialColor, dir, lang } = this.props;

        if (initialStyle) {
            this.setState({ style: initialStyle });
        }
        if (initialColor) {
            this.setState({ color: initialColor });
        }
        if (dir) {
            this.setState({ dir: dir });
        }
        if (lang) {
            this.setState({ lang: lang });
        }

        const language = localStorage.getItem("language");
        if (language !== undefined && language !== null) {
            switch(language) {
                case "עברית": this.setState({dir: "rtl"}); break;
                default: this.setState({dir: "ltr"}); break;
            }
        }
    }

    onChangeTheme(themeState) {
        this.setState(themeState);
    }
    
    render() {
        const { children } = this.props;
        
        return (
            <Provider
                value={{
                    ...this.state,
                    onChangeTheme: this.onChangeTheme.bind(this)
                }}
            >
                { children }
            </Provider>
        );
    }
}
