import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {SidebarEntryAnimate, SlimSidebarAnimate, SlimMenuAnimate} from '../Utils';
import themeContext from '../Theme/ThemeContext';
import { useContext } from 'react';

export function SidebarContent({animationsDisabled, collapsed, pageConfig, slim, children}) {
    const sidebarRef = React.createRef();
    const themeContextHook = useContext(themeContext);

    const [entryAnimationFinished, setEntryAnimationFinished] = useState(false)

    const sidebarClass = classNames('sidebar', 'sidebar--animations-enabled', {
        'sidebar--slim': slim || pageConfig.sidebarSlim,
        'sidebar--collapsed': collapsed || pageConfig.sidebarCollapsed,
        'sidebar--animations-disabled': animationsDisabled || pageConfig.animationsDisabled,
        'sidebar--animate-entry-complete': entryAnimationFinished,
        'sidebar--float-right': themeContextHook.dir === "rtl",
        'sidebar--float-left': themeContextHook.dir === "ltr",
        'sidebar--collapsed-right': (collapsed || pageConfig.sidebarCollapsed) && themeContextHook.dir === "rtl",
        'sidebar--collapsed-left': (collapsed || pageConfig.sidebarCollapsed) && themeContextHook.dir === "ltr",
    });

    useEffect(() => {
        const sidebarEntryAnimate = new SidebarEntryAnimate();
        const slimSidebarAnimate = new SlimSidebarAnimate({direction: themeContextHook.dir});
        const slimMenuAnimate = new SlimMenuAnimate();

        sidebarEntryAnimate.assignParentElement(sidebarRef.current);
        slimSidebarAnimate.assignParentElement(sidebarRef.current);
        slimMenuAnimate.assignSidebarElement(sidebarRef.current);

        sidebarEntryAnimate.executeAnimation()
            .then(() => {
                setEntryAnimationFinished(true)
            });
        
            return () => {
                sidebarEntryAnimate.destroy();
                slimSidebarAnimate.destroy();
                slimMenuAnimate.destroy();
            }
    }, [themeContextHook.dir])

    return (
        <div className={ sidebarClass } ref={ sidebarRef }>
            { children }
        </div>
    );
}

SidebarContent.propTypes = {
    children: PropTypes.node,
    slim: PropTypes.bool,
    collapsed: PropTypes.bool,
    animationsDisabled: PropTypes.bool,
    pageConfig: PropTypes.object
}