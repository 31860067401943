import React from 'react';
import PropTypes from 'prop-types';

const LayoutSidebar = (props) => {
    const sidebarClass = `layout__sidebar ${props.sidebarSlim ? "layout__sidebar--slim" : ""} ${props.sidebarCollapsed ? "layout__sidebar--collapsed" : ""}`

    return (
        <div className={ sidebarClass }>
            { props.children }
        </div>
    );
};

LayoutSidebar.propTypes = {
    children: PropTypes.node,
    sidebarSlim: PropTypes.bool,
    sidebarCollapsed: PropTypes.bool
};
LayoutSidebar.layoutPartName = "sidebar";

export {
    LayoutSidebar
};
