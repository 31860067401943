import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    Input as RSInput
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';

function EditCompany(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [name, setName] = useState("")
    const [type, setType] = useState("Hotel")

    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState("")

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")).then(res => {
                res.response.forEach(item => {
                    if(item.name === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        setName(item.name);
                        setType(item.type);
                        console.log(item)
                        setDataLoading(false);
                    }
                })
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        if(file !== "") {
            const formData = new FormData();
            let headers = new Headers();

            formData.append('file', file);
            formData.append("title", name + " Logo");
            formData.append("description", name + " Logo");

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);

            fetch(`${localStorage.getItem("serverIp")}/file`, {
                        method: 'POST',
                        headers: headers,
                        body: formData,
                    }
                )
                .then((response) => response.json())
                .then((res) => {
                    if(res.success) {
                        console.log(res)
                        updateData(res.file._id)
                    } else {
                        setMessage(res.message)
                    }
                })
                .catch((error) => {
                    setMessage(error)
                });
        } else {
            updateData(undefined)
        }
        
    }

    function updateData(file) {
        //Creates the final rule based on the prepared alias names
        var finalRule = {
            name: name,
            type: type
        };
        if(file !== undefined) {
            finalRule.logo = file
        }

        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "user/companies", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("POST", "user/companies", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    function addFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name)
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new company. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/company" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Add a Company"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Company Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={name} onChange={e => setName(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="logo" id="logoLabel" sm={3}>
                                        Company Logo URL
                                    </Label>
                                    <Col sm={9}>
                                    <RSInput
                                        bsSize='sm'
                                        type="file"
                                        label={fileName || 'choose an image file'}
                                        accept="image/*"
                                        onChange={addFile}
                                        modifiers={{
                                            setMaxHeight: {
                                                enabled: true,
                                                order: 890,
                                                fn: (data) => {
                                                    return {
                                                        ...data,
                                                        styles: {
                                                            ...data.styles,
                                                            overflow: 'auto',
                                                            maxHeight: '200px',
                                                        },
                                                    };
                                                }
                                            }
                                        }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="type" id="typeLabel" sm={3}>
                                        Company Type
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={type}>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Hotel
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Business
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                            {
                                file !== "" && <img src={URL.createObjectURL(file)} alt="Company Logo" style={{marginLeft: "10px", width: "100px"}} />
                            }
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

EditCompany.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditCompany;

