import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
    Input as RSInput,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import PageLoad from '../../components/PageLoad';
import ReactSelect from '../../components/AdvancedSelect';

export default function EditCVItem(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [companies, setCompanies] = useState([])
    const [categories, setCategories] = useState([])
    const [company, setCompany] = useState("")
    const [category, setCategory] = useState("")
    
    const [name, setName] = useState("")
    const [uuid, setUuid] = useState("")

    const [icon, setIcon] = useState("")
    const [iconFile, setIconFile] = useState("")

    const [csvMapping, setCsvMapping] = useState("")
    const [csvMappingFile, setCsvMappingFile] = useState("")
    
    const [imageFileName, setImageFileName] = useState("")
    const [imageFile, setImageFile] = useState("")

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            setDataLoading(true)
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "cv_category")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "cv_item")),
            ])
            .then(([categories, companiesInfo, cvItems]) => {
                setCategories(categories.response)
                cvItems.response.forEach(item => {
                    if (item._id === ruleObj) {
                        setIsEdit(true)
                        setEditId(item._id)
                        setName(item.name)
                        setUuid(item.uuid)
                        setIcon(item.icon)
                        setCategory(item.category._id)
                        setCompany(item.company)
                    }
                })
                setCompanies(companiesInfo.response)
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
            
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        const promiseArr = []
        if(icon !== "") {
            promiseArr.push(uploadFile(iconFile, name + " Icon", "icon"))
        }
        if(csvMapping !== "") {
            promiseArr.push(uploadFile(csvMappingFile, name + " Mapping File", "mapping"))
        }
        if(imageFileName !== "") {
            promiseArr.push(uploadFile(imageFile, name + " Image File", "image"))
        }
        
        if(promiseArr.length === 0) {
            updateData(undefined)
        } else {
            Promise.all(promiseArr)
            .then((res) => {
                var icon = undefined
                var imageFile = undefined
                var csvMapping = undefined
                for(var i = 0; i < res.length; i++) {
                    if(res[i].reference === "icon") {
                        icon = res[i].id
                    }
                    if(res[i].reference === "image") {
                        imageFile = res[i].id
                    }
                    if(res[i].reference === "mapping") {
                        csvMapping = res[i].id
                    }
                }
                updateData(icon, csvMapping, imageFile)
            })
        }
    }

    function uploadFile(file, name, reference) {
        return new Promise ((resolve, reject) => {
            const formData = new FormData();
            let headers = new Headers();

            formData.append('file', file);
            formData.append("title", name);
            formData.append("description", name);

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);
            fetch(`${localStorage.getItem("serverIp")}/file`, {
                method: 'POST',
                headers: headers,
                body: formData,
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success) {
                    console.log(res)
                    resolve({id: res.file._id, reference: reference})
                } else {
                    reject(res.message)
                }
            })
            .catch((error) => {
                reject(error)
            });
        })
        
    }

    function addIconFile(event) {
        setIconFile(event.target.files[0]);
        setIcon(event.target.files[0].name)
    }

    function addCsvMappingFile(event) {
        setCsvMappingFile(event.target.files[0]);
        setCsvMapping(event.target.files[0].name)
    }

    function addImageFile(event) {
        setImageFile(event.target.files[0]);
        setImageFileName(event.target.files[0].name)
    }

    function updateData(icon, csvMapping, image) {
        //Creates the final rule based on the prepared alias names
        var finalRule = {
            name: name,
            company: company !== "" ? company : parseJwt(localStorage.getItem("wsToken")).company[0],
            icon: icon,
            uuid: uuid,
            category: category,
            imageFile: image
        };

        if(csvMapping !== undefined) {
            finalRule.csvMapping = csvMapping
        }

        if (socket !== null && socket.isOpened) {
            if(isEdit) {
                finalRule.id = editId
            }
            console.log(finalRule)
            socket.sendRequest(prepareSystemServiceQuery("PUT", "cv_item", finalRule)).then((res) => {
                console.log(res)
                if (res.response.error === undefined) {
                    toast.success(contentSuccess);
                    setOperation(true);
                } else {
                    setMessage(res.response.error)
                }
            });
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/cv/items" />;
    }

    if (dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="General"
                        subTitle="Update general information regaring your branch"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                {
                                    companies.filter(company => company.isAdmin).length > 0 &&
                                    <FormRow>
                                        <Label for="company" id="companyLabel" sm={3}>
                                            Company
                                        </Label>
                                        <Col sm={9}>
                                            <Dropdown toggle={company}>
                                                {
                                                    companies.map(availableCompany => (
                                                        <DropdownItem key={availableCompany._id} onClick={(e) => setCompany(availableCompany._id)}>
                                                            {availableCompany.slug}
                                                        </DropdownItem>
                                                    ))
                                                }
                                            </Dropdown>
                                        </Col>
                                    </FormRow>
                                }
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Item Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={name} onChange={e => setName(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="category" id="categoryLabel" sm={3}>
                                        Category
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={category}>
                                            {
                                                categories.map(categoryItem => (
                                                    <DropdownItem key={categoryItem._id} onClick={(e) => setCategory(categoryItem._id)}>
                                                        {categoryItem.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="uuid" id="uuidLabel" sm={3}>
                                        UUID <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="uuid" maxLength="52" value={uuid} onChange={e => setUuid(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="logo" id="logoLabel" sm={3}>
                                        Icon
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={icon || 'choose an image file'}
                                            accept="image/*"
                                            onChange={addIconFile}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="image" id="imageLabel" sm={3}>
                                        Image File
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={imageFileName || 'choose an image file'}
                                            accept="image/*"
                                            onChange={addImageFile}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="csvMapping" id="csvMappingLabel" sm={3}>
                                        CSV Mapping
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={csvMapping || 'choose an image file'}
                                            accept="image/*"
                                            onChange={addCsvMappingFile}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>

                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{"Update"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                            {
                                iconFile !== "" && <img src={URL.createObjectURL(iconFile)} alt="Category Icon" style={{marginLeft: "10px", width: "100px"}} />
                            }
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

EditCVItem.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}

