import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import {Link} from "react-router-dom";
import { AdvancedTable, ButtonGroup, Button, DeletionModal } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';

export default function CVCategory() {
    const {socket} = useSocket();

    const [ruleValues, setRuleValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableNote, setTableNote] = useState("");

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "cv_category")).then(res => {
                if (res.response !== undefined) {
                    setRuleValues(res.response);
                    setLoading(false)
                }
            });
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setRuleValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "cv_category", {id: cell})).then((res) => {
                    if(res.response.error) {
                        setTableNote(res.response.error)
                    } else {
                        setTableNote("")
                    }
                    refreshValues()
                })
            }
        }
    }
    
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="CV Categories"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <AdvancedTable
                                to="/cv/categories/add"
                                loading={loading}
                                tableNote={<div className='text-red'>{tableNote}</div>}
                                columns={[
                                    {
                                        dataField: 'name',
                                        text: 'Category Name',
                                        sort: true
                                    }, {
                                        dataField: 'type',
                                        text: 'Category Type',
                                        sort: true
                                    }, {
                                        dataField: "parent",
                                        text: "Parent",
                                        sort: false
                                    }, {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell[0]}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell[1] + ""} />
                                                    <Button tag={Link} to={{
                                                            pathname: '/cv/categories/edit/' + cell[1],
                                                            id: cell[1],
                                                        }}>
                                                        Edit
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        } 
                                    }
                                ]}
                                keyField="name"
                                data={
                                    Array.isArray(ruleValues) ? ruleValues.map(returnResource => ({
                                        ...returnResource,
                                        parent: returnResource.ref ? returnResource.ref.name : "no",
                                        actions: [returnResource.name, returnResource._id]
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}