import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import { AdvancedTable, Button, ButtonGroup, DeletionModal } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';

export function Advertisements() {
    const {socket} = useSocket();

    const [tableValues, setTableValues] = useState([]);
    const [tableNote, setTableNote] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "advertise")).then(res => {
                console.log(res.response)
                if(res.response !== undefined) {
                    setTableValues(res.response);
                }
                setLoading(false)
            }).catch(() => setLoading(false));
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setTableValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "advertise", {id: cell})).then((res) => {
                    if(res.response.error) {
                        setTableValues(res.response.error)
                    } else {
                        setTableNote("")
                    }
                    refreshValues()
                })
            }
        }
    }
    
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Advertise On Mirrors"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <AdvancedTable
                                to="/specular/ads/add"
                                loading={loading}
                                tableNote={<div className='text-red'>{tableNote}</div>}
                                columns={[
                                    {
                                        dataField: 'isEnabled',
                                        text: 'Enabled',
                                        sort: true,
                                        formatter: function name(cell) {
                                            return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <i className="fa fa-fw fa-circle text-danger ml-auto align-self-center"></i>
                                        }
                                    }, {
                                        dataField: 'image',
                                        text: 'Image',
                                        sort: true,
                                        formatter: function name(cell) {
                                            return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <i className="fa fa-fw fa-circle text-danger ml-auto align-self-center"></i>
                                        }
                                    }, {
                                        dataField: 'url',
                                        text: 'URL',
                                        sort: true,
                                        formatter: function name(cell) {
                                            return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <i className="fa fa-fw fa-circle text-danger ml-auto align-self-center"></i>
                                        }
                                    }, {
                                        dataField: 'title',
                                        text: 'Title',
                                        sort: true
                                    }, {
                                        dataField: 'text',
                                        text: 'Text',
                                        sort: true
                                    }, /*{
                                        dataField: 'fromTime',
                                        text: 'From Time',
                                        sort: true
                                    }, {
                                        dataField: 'toTime',
                                        text: 'From Time',
                                        sort: true
                                    }, */{
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell[0]}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell[1] + ""} />
                                                    <Button tag={Link} to={{
                                                            pathname: '/specular/ads/edit/' + cell[1],
                                                            id: cell[1],
                                                        }}>
                                                        Edit
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        } 
                                    }
                                ]}
                                keyField="_id"
                                data={
                                    Array.isArray(tableValues) ? tableValues.map(returnResource => ({
                                        ...returnResource,
                                        actions: [returnResource.title, returnResource._id]
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default Advertisements;