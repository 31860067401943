import React, { useContext } from "react";
import {v4 as uuid} from "uuid";
import PropTypes from 'prop-types';
import { CustomInput } from "reactstrap";
import classNames from 'classnames'
import themeContext from "./Theme/ThemeContext";

export const GboxSwitch = ({id, label, value, setValue, className}) => {
    const theme = useContext(themeContext)
    return (
        <div style={{ height: "100%", display: "flex", alignItems: "center", paddingBottom: "1px" }}>
            <CustomInput
                id={id}
                type="switch"
                label={label ? label : (value ? "Enabled" : "Disabled")}
                onChange={() => setValue(!value)}
                checked={value}
            />
        </div>
    )
}


GboxSwitch.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool.isRequired,
    setValue: PropTypes.func.isRequired,
    className: PropTypes.string
}

GboxSwitch.defaultProps = {
    id: uuid(),
    className: ""
}