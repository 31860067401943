import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { withPageConfig } from './../Layout';
import { SidebarContent } from './SidebarContent';
import { useOuterClick } from 'react-outer-click';

const Sidebar = (props) => {
    const el = useRef(null);

    useOuterClick(el, () => {
        if(!props.pageConfig.sidebarCollapsed && (
            props.pageConfig.screenSize === 'xs' ||
            props.pageConfig.screenSize === 'sm' ||
            props.pageConfig.screenSize === 'md'
        )) {
            props.pageConfig.toggleSidebar();
        }
    });
    return (
        <React.Fragment>
            { /* Enable OuterClick only in sidebar overlay mode */}
            <div ref={el}>
                <SidebarContent { ...props } />
            </div>
        </React.Fragment>
    )
};

Sidebar.propTypes = {
    children: PropTypes.node,
    slim: PropTypes.bool,
    collapsed: PropTypes.bool,
    animationsDisabled: PropTypes.bool,
    pageConfig: PropTypes.object
};

const cfgSidebar = withPageConfig(Sidebar);

export {
    cfgSidebar as SidebarInfo
};
