import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';

function EditRemoteDevice(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [name, setName] = useState("")
    const [serialNumber, setSerialNumber] = useState("")
    const [roomNumber, setRoomNumber] = useState("")
    const [branch, setBranch] = useState("")

    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)

    const [availableBranches, setAvailableBranches] = useState([])

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "remote")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch"))
            ]).then(([remoteInfo, companiesInfo, branchesInfo]) => {
                remoteInfo.response.forEach(item => {
                    if(item.name === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        setName(item.name);
                        setSerialNumber(item.serial);
                        setRoomNumber(item.roomNumber);

                        if(item.branch) setBranch(item.branch._id);
                        if(item.latitude) setLatitude(item.latitude)
                        if(item.longitude) setLongitude(item.longitude)

                        setDataLoading(false)
                    }
                })
                var branches = []
                console.log(companiesInfo.response)
                console.log(branchesInfo.response)
                companiesInfo.response.forEach(company => {
                    branchesInfo.response.forEach(branch => {
                        if(branch.company === company._id) {
                            branches = [...branches, branch]
                        }
                    })
                })
                setAvailableBranches(branches)
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            name: name,
            serial: serialNumber,
            roomNumber: roomNumber,
            branch: branch,
            longitude: longitude,
            latitude: latitude,
        };

        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "remote", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("POST", "remote", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/remote" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Add a Remote Device"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={name} onChange={e => setName(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="hotel" id="hotelLabel" sm={3}>
                                        Branch
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={branch}>
                                            {
                                                availableBranches.map(availableHotel => (
                                                    <DropdownItem key={availableHotel._id} onClick={(e) => setBranch(availableHotel._id)}>
                                                        {availableHotel.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="serialNumber" id="serialNumberLabel" sm={3}>
                                        Serial Number
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="serialNumber" maxLength="52" value={serialNumber} onChange={e => setSerialNumber(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="roomNumber" id="roomNumberLabel" sm={3}>
                                        Room Number
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="roomNumber" maxLength="52" value={roomNumber} onChange={e => setRoomNumber(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="location" id="locationLabel" sm={3}>
                                        Location
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Latitude</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="number" id="latitude" maxLength="52" value={latitude} onChange={e => setLatitude(e.target.value)} required />
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Longitude</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="number" id="location" maxLength="52" value={longitude} onChange={e => setLongitude(e.target.value)} required />
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>
        </Container>
    );
}

EditRemoteDevice.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditRemoteDevice;

