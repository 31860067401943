import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    CardHeader,
    DropdownItem
} from 'reactstrap';
import { ThemeConsumer } from "../../components/Theme"
import { HeaderMain } from "../../components/HeaderMain";
import { useSocket, preparePfsenseServiceQuery, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import { Button, Dropdown, FormRow } from '../../components';

function Log() {
    const { socket } = useSocket();
    const [result, setResult] = useState("");

    const [selectedRemote, setSelectedRemote] = useState("Select Mirror");
    const [availableRemotes, setAvailableRemotes] = useState([]);

    useEffect(() => {
        getValues();
    }, [socket]);

    function getValues() {
        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "remote")).then(res => {
                if(res.response) {
                    console.log(res.response)
                    setAvailableRemotes(res.response);
                }
            });
        }
    }

    function getLog() {
        if (socket !== null && socket.isOpened) {
            socket.sendRequest(preparePfsenseServiceQuery("POST", "diagnostics/ping", {})).then(res => {
                if (res.response.code === 200) {
                    if (res.response.message === "Success") {
                        setResult(res.response.data);
                    }
                    if (res.response.message === "Failed") {
                        //setMessage("Rule update failed");
                    }
                } else {
                    //setMessage(res.response.message)
                }
            });
        }
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Ping Utility"
                        className="mb-4 mb-lg-5"
                    />
                </Col>
                <Col lg={12}>
                    <Card className="mb-3">
                        <CardHeader>
                            Set Mirror
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="selectedRemote" id="selectedRemoteLabel" sm={3}>
                                        Mirror
                                        <HelpPopup header="Mirror" target="selectedRemoteLabel" icon>
                                            Set the selected mirror to receive its log.
                                        </HelpPopup>
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={selectedRemote} onChange={e => console.log(e.target.value)}>
                                            {
                                                availableRemotes.map(remote => (
                                                    <DropdownItem key={remote._id} onClick={() => setSelectedRemote(remote.name)}>
                                                        {remote.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>

                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} style={{ width: "100px" }} onClick={getLog}>Test</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
                {
                    result !== "" &&
                    <Col lg={12}>
                        <Card className="mb-3">
                            <CardHeader>
                                Result
                            </CardHeader>
                            <CardBody>
                                <p style={{ whiteSpace: 'break-spaces' }}>
                                    {result}
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </Container>
    );
}
export default Log;