import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container, CardHeader, Form, Media, Label } from 'reactstrap';
import {Link} from "react-router-dom";
import { AdvancedTable, ButtonGroup, Button, DeletionModal, FormRow, Input } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';
import { ThemeConsumer } from '../../components/Theme';
import { toast } from 'react-toastify';

export function ShortenLinks() {
    const {socket} = useSocket();

    const [ruleValues, setRuleValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableNote, setTableNote] = useState("");
    const [message, setMessage] = useState("")

    const [gotoLink, setGotoLink] = useState("")

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "links")).then(res => {
                if(res.response !== undefined) {
                    setRuleValues(res.response);
                    setLoading(false)
                }
            });
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setRuleValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "links", {id: cell})).then((res) => {
                    if(res.response.error) {
                        setTableNote(res.response.error)
                    } else {
                        setTableNote("")
                        toast.success(contentSuccess)
                    }
                    refreshValues()
                })
            }
        }
    }

    function addToShorten() {
        fetch(`${localStorage.getItem("serverIp")}/otg/shorten_link`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                goto: gotoLink
            }),
        })
        .then((response) => response.json())
        .then((res) => {
            if(res.goto) {
                console.log(res)
                refreshValues()
                toast.success(contentSuccess)
            } else {
                setMessage(res.message)
            }
        })
        .catch((error) => {
            setMessage(error)
        });
    }


    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    Action Done Successfully
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );
    
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Files"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12} style={{marginBottom: "20px"}}>
                    <Card>
                        <CardHeader>
                            Shorten Links
                            {message !== "" && " - " + message}
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Col sm={12} style={{marginTop: "10px"}}>
                                        <Input name="goto" type="text" value={gotoLink} onChange={(e) => setGotoLink(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col sm={12} style={{marginTop: "10px"}}>
                                        <ThemeConsumer>
                                            {
                                                ({ color }) => (
                                                    /* Make color as the same of parent */
                                                    <Button color={color} outline={false} size="md" onClick={addToShorten}>Shorten Link!</Button>
                                                )
                                            }
                                        </ThemeConsumer>
                                    </Col>
                                </FormRow>
                            </Form>

                        </CardBody>
                    </Card>
                
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardHeader>
                            Available Links
                        </CardHeader>
                        <CardBody>
                            <AdvancedTable
                                loading={loading}
                                tableNote={<div className='text-red'>{tableNote}</div>}
                                expandRow={<div>HELLO</div>}
                                columns={[
                                    {
                                        dataField: '_id',
                                        text: 'ID',
                                        sort: true
                                    }, 
                                    {
                                        dataField: 'sid',
                                        text: 'Link Hash',
                                        sort: true
                                    }, {
                                        dataField: 'goto',
                                        text: 'Target',
                                        sort: true
                                    }, {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell[0]}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell[1] + ""} />
                                                        <Button onClick={() => {

                                                        } }>
                                                            Copy Link
                                                        </Button>
                                                </ButtonGroup>
                                            );
                                        } 
                                    }
                                ]}
                                keyField="_id"
                                data={
                                    Array.isArray(ruleValues) ? ruleValues.map(returnResource => ({
                                        ...returnResource,
                                        key: returnResource._id,
                                        actions: [returnResource.goto, returnResource._id]
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default ShortenLinks;