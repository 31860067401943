import React from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";

export const GboxButton = ({children, ...props}) => {
    return (
        <Button
            { ...props }
        >
            { children }
        </Button>
    );
}

GboxButton.propTypes = {
    size: PropTypes.string,
    outline: PropTypes.bool,
    children: PropTypes.node
}

GboxButton.defaultProps = {
    size: 'sm',
    outline: true
}