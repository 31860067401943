import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import { Link } from "react-router-dom";
import { AdvancedTable, ButtonGroup, Button, DeletionModal } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { useSocket, prepareSystemServiceQuery, prepareOAuthAccessQuery } from '../../contexts/SocketContext';

export function Service() {
    const { socket } = useSocket();

    const [ruleValues, setRuleValues] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        document.body.appendChild(script);
    }, [])

    function refreshValues() {
        if (socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "service")).then(res => {
                if (res.response !== undefined) {
                    setRuleValues(res.response);
                    setLoading(false)
                }
            });

            /*socket.sendRequest({
                request_type: "ServiceQuery",
                action: "pms",
                uri: "GetCalendarEvents",
                requested_service: "default"
              })
            .then(res => {
                console.log(res)
            })*/
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setRuleValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "service", { id: cell })).then(() => {
                    refreshValues()
                })
            }
        }
    }

    function handleOAuth(cell) {
        console.log(cell)
        const client = window.google.accounts.oauth2.initCodeClient({
            client_id: cell.username,//your client id created in cloud console,
            scope: cell.scopes,
            ux_mode: 'popup',
            callback: async (response) => {
                try {
                    if (!response.code) {
                        return;
                    }
                    console.log(response)
                    socket.sendRequest(prepareOAuthAccessQuery(cell._id, response.code))
                    .then(res => {
                        console.log(res)
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        });
        client.requestCode();
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Services"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <AdvancedTable
                                to="/app/service/add"
                                loading={loading}
                                columns={[
                                    {
                                        dataField: 'servicename',
                                        text: 'Name',
                                        sort: true
                                    }, {
                                        dataField: 'default',
                                        text: 'Default',
                                        sort: true,
                                        formatter: function name(cell) {
                                            return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <span></span>
                                        }
                                    }, {
                                        dataField: 'type',
                                        text: 'Type',
                                        sort: true
                                    }, {
                                        dataField: 'ip',
                                        text: 'IP',
                                        sort: true
                                    }, {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell.servicename}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell._id + ""} />
                                                    <Button tag={Link} to={{
                                                        pathname: '/app/service/edit/' + cell.servicename,
                                                        id: cell.servicename,
                                                    }}>
                                                        Edit
                                                    </Button>
                                                    {
                                                        (cell.type === "ApaleoPMS") && !cell.tokenInfo &&
                                                        <Button href={`https://identity.apaleo.com/connect/authorize?response_type=code&scope=${cell.scopes}&client_id=${cell.username}&redirect_uri=${cell.loginendpoint}&state=${cell._id}`}>
                                                            Authorize
                                                        </Button>
                                                    }
                                                    {
                                                        (cell.type === "GoogleCalendar") &&
                                                        <Button onClick={() => handleOAuth(cell)}>
                                                            Authorize
                                                        </Button>
                                                    }

                                                </ButtonGroup>
                                            );
                                        }
                                    }
                                ]}
                                keyField="servicename"
                                data={
                                    Array.isArray(ruleValues) ? ruleValues.map(returnResource => ({
                                        ...returnResource,
                                        actions: returnResource
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default Service;