import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import classNames from 'classnames';

import {
    NavLink,
    NavItem,
} from 'reactstrap';

import { Nav } from './Nav';

const NavbarNavigation = ({ accent, pills, ...navbarProps }) => {
    return (
        <Nav navbar accent={accent} pills={pills} {...navbarProps} className="new-navbar">
            <NavItem>
                <NavLink tag={Link} to="/specular/dashboard">
                    <span className={classNames({ 'mr-3': !(pills || accent) })}>
                        <i className="fa fa-fw fa-home d-md-inline"></i>
                    </span>
                </NavLink>
            </NavItem>
        </Nav>
    );
}
NavbarNavigation.propTypes = {
    pills: PropTypes.bool,
    accent: PropTypes.bool,
};


export { NavbarNavigation };
