import React, { useState } from "react";
import {
    Modal, Label, ModalBody, ModalFooter,
    Container, Row, Col
} from "reactstrap";
import PropTypes from "prop-types";
import { GboxFormRow as FormRow } from "./FormRow";
import { GboxButton as Button } from "./Button";

function ConfirmationModal({buttonDom, heading, message, successFunction, ...props}) {
    const [modalOpened, setModalOpen] = useState(false);

    function toggleMe() {
        setModalOpen(!modalOpened);
    }

    return (
        <div onClick={() => setModalOpen(!modalOpened)} {...props}>
            {buttonDom}
            <Modal isOpen={modalOpened}
                toggle={toggleMe}
                className="modal-outline-dark">
                <ModalBody className="text-center px-3">
                    <Container>
                        <Row>
                            <Col className="text-center py-3">
                                <h2>{heading}</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormRow>
                                    <Label sm={12}>
                                        {message}
                                    </Label>
                                </FormRow>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter className="py-3">
                    <Button color="info" outline={false} className="mr-2" onClick={() => {
                        successFunction();
                        setModalOpen(false);
                    }}>
                        Confirm
                    </Button>
                    <Button className="text-info" onClick={() => { setModalOpen(false) }}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

ConfirmationModal.propTypes = {
    buttonDom: PropTypes.element,
    heading: PropTypes.string,
    message: PropTypes.string,
    successFunction: PropTypes.func
}

ConfirmationModal.defaultProps = {
    buttonDom: <h1>Dom</h1>,
    heading: '',
    message: '',
    successFunction: () => { return; }
}

export default ConfirmationModal;