import React, { useEffect, useContext } from 'react';
import {
    Container,
    Row,
    Table,
    Col
} from 'reactstrap';
import {
    PageConfigContext
} from "../../components/Layout"
import { HeaderMain } from "../../components/HeaderMain";
import {
    CardSystem
} from "../../components/CardSystem"

function PmsDashboard() {

    const pageConfig = useContext(PageConfigContext)
    useEffect(() => {
        pageConfig.setElementsVisibility({
            navbarHidden: false,
            sidebarHidden: false,
            footerHidden: false 
        })
    }, [])

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="System Dashboard"
                        className="mb-4 mb-lg-5"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardSystem
                        title="Memory"
                        badgeColor="primary"
                        pieColor="primary"
                        caret={10 > 0 ? "up" : "down"}
                        percent={100}
                        value={100}
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardSystem
                        title="CPU"
                        unit="%"
                        badgeColor="purple"
                        pieColor="purple"
                        caret={100 > 0 ? "up" : "down"}
                        percent={Math.abs(100)}
                        value={100}
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardSystem
                        title="Traffic"
                        unit="Kb"
                        badgeColor="success"
                        pieColor="success"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardSystem
                        title="Disk Storage"
                        unit="%"
                        pieColor="yellow"
                        caret={100 > 0 ? "up" : "down"}
                        percent={100}
                        value={Math.round(100)}
                    />
                </Col>
                <Col lg={12}>
                    <h6 className="mt-5">Running Services</h6>

                    <Table responsive>
                        <thead>
                            <tr>
                                <th><div className="d-flex justify-content-center">Name</div></th>
                                <th><div className="d-flex justify-content-center">Status</div></th>
                                <th><div className="d-flex justify-content-center">Memory</div></th>
                                <th><div className="d-flex justify-content-center">CPU</div></th>
                                <th><div className="d-flex justify-content-center">Description</div></th>
                                <th><div className="d-flex justify-content-center">Actions</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default PmsDashboard;