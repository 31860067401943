import ReactSelect from "react-select";

export default function AdvancedSelect({value, options, onChange, isMulti}) {
    return (
        <ReactSelect
            value={value}
            isMulti={isMulti}
            onChange={onChange}
            options={options}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    background: '#F9FAFC',
                    borderColor: '#DEE2E6',
                    minHeight: '30px',
                    height: '30px',
                    boxShadow: state.isFocused ? null : null,
                }),

                valueContainer: (provided, state) => ({
                    ...provided,
                    height: '30px',
                    padding: '0 6px'
                }),

                input: (provided, state) => ({
                    ...provided,
                    margin: '0px',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
                indicatorsContainer: (provided, state) => ({
                    ...provided,
                    height: '30px',
                }),
                multiValue: (provided, state) => ({
                    ...provided,
                    height: '18px',
                }),
                multiValueLabel: (provided, state) => ({
                    ...provided,
                    fontSize: 13,
                    alignItems: "center",
                    display: "flex"
                })
            }}
        />
    )
}