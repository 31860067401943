import React from "react";
import PropTypes from 'prop-types';
import { ButtonGroup } from "reactstrap";

export const GboxButtonGroup = ({children, ...props}) => {
    return (
        <ButtonGroup dir="ltr"
            { ...props }
        >
            { children }
        </ButtonGroup>
    );
}

GboxButtonGroup.propTypes = {
    size: PropTypes.string,
    children: PropTypes.node
}

GboxButtonGroup.defaultProps = {
    size: 'sm'
}