import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup, Switch } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import PageLoad from '../../components/PageLoad';

function EditService(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [name, setName] = useState("")
    const [ip, setIp] = useState("")
    const [loginEndpoint, setLoginEndpoint] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [hotelId, setHotelId] = useState("")
    const [type, setType] = useState("")
    const [category, setCategory] = useState("PMS")
    const [companies, setCompanies] = useState([])
    const [scopes, setScopes] = useState("")
    const [defaultService, setDefaultService] = useState(false)

    const [appParams, setAppParams] = useState([{label: "", paramName: ""}])

    const [availaleCompanies, setAvailableCompanies] = useState([]);

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")).then(res => {
                setAvailableCompanies(res.response);
            })
            socket.sendRequest(prepareSystemServiceQuery("GET", "service")).then(res => {
                res.response.forEach(item => {
                    if(item.servicename === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        setName(item.servicename);
                        setLoginEndpoint(item.loginendpoint);
                        setIp(item.ip);
                        switch(item.type) {
                            default:
                            case "ApaleoPMS": setType("ApaleoPMS"); break;
                            case "MiniHotelPMS": setType("MiniHotelPMS"); break;
                            case "GoogleCalendar": setType("Google Calendar"); break;
                        }
                        setCompanies(item.company.map(company => company.name));
                        
                        if(item.hotelId) {
                            setHotelId(item.hotelId)
                        }
                        if(item.default) {
                            setDefaultService(item.default)
                        }
                        if(item.scopes) {
                            setScopes(item.scopes)
                        }
                        
                        switch(item.category) {
                            default:
                            case "pms": setCategory("PMS"); break;
                            case "office": setCategory("Office"); break;
                        }

                        if(item.appParams && item.appParams.length > 0) {
                            console.log(item.appParams)
                            setAppParams([...item.appParams, {label: "", paramName: ""}])
                        }

                        setDataLoading(false)
                    }
                })
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    function handleChangeInput(i, event, array, setMethod) {
        const values = [...array];
        const { name, value } = event.target;
        values[i][name] = value;
        setMethod(values);
    }

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            servicename: name,
            ip: ip,
            loginendpoint: loginEndpoint,
            company: companies,
            scopes: scopes,
            default: defaultService,
            appParams: appParams.filter(val => val.label !== "")
        };

        switch(type) {
            default:
            case "ApaleoPMS": finalRule.type = "ApaleoPMS"; break;
            case "MiniHotelPMS": finalRule.type = "MiniHotelPMS"; break;
            case "Google Calendar": finalRule.type = "GoogleCalendar"; break;
            case "Firebase": finalRule.type = "Firebase"; break;
        }

        switch(category) {
            default:
            case "PMS": finalRule.category = "pms"; break;
            case "Office": finalRule.category = "office"; break;
        }

        if(username !== "") {
            finalRule.username = username;
        }
        if(password !== "") {
            finalRule.password = password;
        }
        if(hotelId !== "") {
            finalRule.hotelId = hotelId;
        }
        
        console.log(finalRule)
        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "service", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("POST", "service", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/service" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title={`${isEdit ? "Edit " + name : "Add a new"} Service`}
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={name} onChange={e => setName(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="category" id="categoryLabel" sm={3}>
                                        Category
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={category}>
                                            <DropdownItem onClick={(e) => setCategory(e.currentTarget.textContent)}>
                                                PMS
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setCategory(e.currentTarget.textContent)}>
                                                Office
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="type" id="typeLabel" sm={3}>
                                        Type
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={type}>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                ApaleoPMS
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                MiniHotelPMS
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Google Calendar
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Firebase
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="ip" id="ipLabel" sm={3}>
                                        IP
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="ip" maxLength="150" value={ip} onChange={e => setIp(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="defaultService" id="defaultServiceLabel" sm={3}>
                                        Set As Default?
                                    </Label>
                                    <Col sm={9}>
                                        <Switch value={defaultService} setValue={setDefaultService} />
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            Credentials
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="username" id="usernameLabel" sm={3}>
                                        Service Username
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="username" maxLength="200" value={username} onChange={e => setUsername(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="password" id="passwordLabel" sm={3}>
                                        Service Password
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="password" id="password" maxLength="200" value={password} onChange={e => setPassword(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="hotelId" id="hotelIdLabel" sm={3}>
                                        Hotel ID
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="hotelId" maxLength="150" value={hotelId} onChange={e => setHotelId(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="loginEndpoint" id="loginEndpointLabel" sm={3}>
                                        Login URI Endpoint
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="loginEndpoint" maxLength="150" value={loginEndpoint} onChange={e => setLoginEndpoint(e.target.value)} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="scopes" id="scopesLabel" sm={3}>
                                        Available Scopes
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="scopes" maxLength="300" value={scopes} onChange={e => setScopes(e.target.value)} />
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            Available Parameters
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    {appParams.map((item, index) => (
                                        <Col sm={12} key={index}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Parameter Key</InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" name="label" maxLength="52" value={item.label} onChange={e => {
                                                    handleChangeInput(index, e, appParams, setAppParams)
                                                    if((e.target.value.length > 0 || item.paramName.length > 0)) {
                                                        if(appParams[index + 1] === undefined) {
                                                            setAppParams([...appParams, {label: "", paramName: ""}])
                                                        }
                                                        
                                                    } else {
                                                        setAppParams(appParams.slice(0, -1))
                                                    }
                                                }} />
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Parameter Value</InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" name="paramName" maxLength="52" value={item.paramName} onChange={e => {
                                                    handleChangeInput(index, e, appParams, setAppParams)
                                                    if((e.target.value.length > 0 || item.label.length > 0)) {
                                                        if(appParams[index + 1] === undefined) {
                                                            setAppParams([...appParams, {label: "", paramName: ""}])
                                                        }
                                                        
                                                    } else {
                                                        setAppParams(appParams.slice(0, -1))
                                                    }
                                                }} />
                                            </InputGroup>
                                        </Col>
                                    ))}
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    
                    <Card className="mb-3">
                        <CardHeader>
                            Companies
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="companies" id="companiesLabel" sm={3}>
                                        Select Companies
                                    </Label>
                                    <HelpPopup header="Companies" target="companiesLabel">
                                        The companies this user will belong to, will affect visibility of menus.
                                    </HelpPopup>
                                    <Col sm={9}>
                                        <Input
                                            type="select"
                                            name="companies"
                                            id="companies"
                                            value={companies}
                                            onChange={e => setMultiple(e, setCompanies)}
                                            multiple
                                            style={{ height: "150px" }}
                                        >
                                            {
                                                availaleCompanies.map(rule => (
                                                    <option value={rule.name} key={rule.name}>
                                                        {rule.name}
                                                    </option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

EditService.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditService;

