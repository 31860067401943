import { createContext, useContext } from 'react';

/*
SelectedService is a way to query different devices based on their
ServiceCredentials (IP, username, password..). The user can change
the selected service and work on another device seemlessly.
*/
export const SelectedServiceContext = createContext();
export function useSelectedService() {
  return useContext(SelectedServiceContext);
}