import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import { AdvancedTable } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';

export function Feedback() {
    const {socket} = useSocket();

    const [tableValues, setTableValues] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "feedback")).then(res => {
                if(res.response !== undefined) {
                    setTableValues(res.response);
                }
                setTableValues([{
                    time: "1",
                    name: "Kof",
                    room: "A1",
                    stars: 5,
                    message: "lorem ipsum dorem asdas asdadas ad asd ad asdas da dasd asd"
                },
                {
                    time: "2",
                    name: "Bod",
                    room: "A2",
                    stars: 3,
                    message: "lorem ipsum dorem asdas asdadas ad asd ad asdas da dasd asd"
                }])
                setLoading(false)
            }).catch(() => setLoading(false));
        }
    }
    
    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Guest Feedbacks"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <AdvancedTable
                                loading={loading}
                                columns={[
                                    {
                                        dataField: 'time',
                                        text: 'Time',
                                        sort: true
                                    }, {
                                        dataField: 'name',
                                        text: 'Guest Name',
                                        sort: true
                                    }, {
                                        dataField: 'room',
                                        text: 'Room',
                                        sort: true
                                    }, {
                                        dataField: 'stars',
                                        text: 'Stars',
                                        sort: true
                                    }, {
                                        dataField: 'message',
                                        text: 'Message',
                                        sort: true
                                    }
                                ]}
                                keyField="time"
                                data={
                                    Array.isArray(tableValues) ? tableValues.map(returnResource => ({
                                        ...returnResource
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

    );
}

export default Feedback;