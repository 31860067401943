import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import PageLoad from '../../components/PageLoad';

function General(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [branchName, setBranchName] = useState("")

    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            setDataLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "business",)).then(res => {
                if (res.response !== undefined) {
                    res.response.forEach(item => {
                        if (item._id === ruleObj) {
                            setIsEdit(true)
                            setEditId(item._id)
                            setBranchName(item.name)
                            setLatitude(item.latitude)
                            setLongitude(item.longitude)
                            
                            setDataLoading(false)
                        }
                    })
                }
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            branchName: branchName,
            longitude: longitude,
            latitude: latitude
        };

        if (socket !== null && socket.isOpened) {
            if(isEdit) {
                finalRule.id = editId
            }
            console.log(finalRule)
            socket.sendRequest(prepareSystemServiceQuery("PUT", "business", finalRule)).then((res) => {
                console.log(res)
                if (res.response.error === undefined) {
                    toast.success(contentSuccess);
                    setOperation(true);
                } else {
                    setMessage(res.response.error)
                }
            });
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/business" />;
    }

    if (dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="General"
                        subTitle="Update general information regaring your branch"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Branch Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={branchName} onChange={e => setBranchName(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="location" id="locationLabel" sm={3}>
                                        Location <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Longitude</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="number" id="location" maxLength="52" value={longitude} onChange={e => setLongitude(e.target.value)} required />
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Latitude</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="number" id="latitude" maxLength="52" value={latitude} onChange={e => setLatitude(e.target.value)} required />
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{"Update"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

General.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default General;

