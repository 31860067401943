import React from "react";
import PropTypes from "prop-types";
import {UncontrolledPopover, PopoverHeader, PopoverBody} from "reactstrap";

export default function HelpPopup(props) {
    return (
        <UncontrolledPopover placement="top" target={props.target} trigger="legacy" style={{border: "1px gray solid", borderRadius: "3px"}}>
            <PopoverHeader>
                {
                    props.header !== undefined ? props.header : ""
                }
            </PopoverHeader>
            <PopoverBody style={{ color: "#D3D3D3" }}>
                {
                    props.children !== undefined &&
                    props.children
                }
            </PopoverBody>
        </UncontrolledPopover>
    );
}

HelpPopup.propTypes ={
    target: PropTypes.string.isRequired,
    header: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}