import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import WebSocketAsPromised from 'websocket-as-promised';

import AppLayout from "./AppLayout"
import { RoutedContent } from '../routes';
import { SelectedServiceContext } from '../contexts/SelectedServiceContext';
import { SocketContext } from '../contexts/SocketContext';
import { getParsedJwtToken } from '../routes/PrivateRoute';


const basePath = process.env.BASE_PATH || '/';

function AppClient() {
    const [socket, setSocket] = useState(null); //This is the global socket object for querying the server.
    const [selectedService, setSelectedService] = useState("Select Mirror"); //This provider will be the selected service within the application chosen. This will be used throughout the app.
    const [reconnectTimer, setReconnectTimer] = useState(null); //Due to the way the socket is implemented, a timer is setup when the socket closes.

    //Upon every refresh of the browser it loads the configuration from localStorage
    useEffect(() => {
        setReconnectTimer(true);

        const selectedService = localStorage.getItem("selectedService");
        if (selectedService !== undefined && selectedService !== null) {
            setSelectedService(selectedService);
        }
    }, [])

    //Reconnection on error, triggered when reconnectTimer changes
    useEffect(() => {
        var timer = null;
        if (reconnectTimer === true) {
            startWs(localStorage.getItem("wsToken"), );
            //Attemping reconnection every 10 seconds
            timer = setInterval(() => {
                const existingToken = localStorage.getItem("wsToken");
                if (existingToken !== undefined && existingToken !== null && getParsedJwtToken(existingToken)) {
                    console.log("Attemping to reconnect to the server");
                    startWs(existingToken);
                } else {
                    clearInterval(timer);
                }
            }, 10000);
        } else {
            if (timer !== null) {
                clearInterval(timer)
            }
        }
        return () => {
            if (timer !== null) {
                clearInterval(timer)
            }
        }
    }, [reconnectTimer])


    //The set of const functions are sent with their corresponding providers to provide dynamic saving of the states to localStorage
    const setService = (data) => {
        localStorage.setItem("selectedService", data);
        setSelectedService(data);
    }

    function startWs(token) {
        //When the token is undefined a termination notice was sent
        if (token === undefined || token === null) {
            setSocket(null);
            return;
        }
        const serverIp = localStorage.getItem("serverIp")
        var newServerIp = serverIp.replace("http://", "ws://").replace("https://", "wss://");

        const wsp = new WebSocketAsPromised((newServerIp === "" ? window.location.hostname : newServerIp) + "/ws?token=" + token, {
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data),
            attachRequestId: (data, requestId) => Object.assign({ requestId: requestId }, data), // attach requestId to message as `id` field
            extractRequestId: data => data && data.requestId,
            timeout: 5000
        });
        wsp.open()
            .then(() => {
                wsp.sendRequest({ request_type: "initial_user_connection", message: 'Hello World' }).then(res => console.log(res.message))
                setSocket(wsp);
                setReconnectTimer(false);
            })
            .then(() => {
                wsp.onError.addListener(e => {
                    console.log("error 1")
                    console.log(e)
                });
                wsp.onClose.addListener(e => {
                    if (e.code === 1006) { //When the connection closed due to an error try to reconnect
                        wsp.close();
                        setSocket(null);
                        setReconnectTimer(true);
                    }
                });
            })
            .catch((a) => {
                console.log("Connection failed")
                console.log(token)
                console.log(a)
            });
    }


    return (
        <SocketContext.Provider value={{ socket, setSocketConn: startWs }}>
            <SelectedServiceContext.Provider value={{ selectedService, setSelectedService: setService }}>
                <Router basename={basePath}>
                    <AppLayout>
                        <RoutedContent />
                    </AppLayout>
                </Router>
            </SelectedServiceContext.Provider>
        </SocketContext.Provider>
    )
}

export default AppClient;