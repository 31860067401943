import React from "react"
import { Container, Label } from "reactstrap"

const PageLoad = () => {
    return (
        <Container style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}} className="pageLoader">
            <div className="spinner" />
            <Label size="lg">Data Loading</Label>
        </Container>
    )
};

export default PageLoad;