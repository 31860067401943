import React from 'react';
import {
    Route,
    Switch,
    Redirect
} from 'react-router';

import PrivateRoute from "./PrivateRoute"

import Login from './Login/Login';
import Error404 from './Error404/Error404';

import AppFileManager from './AppSettings/FileManager';
import AppLinkShorten from './AppSettings/ShortenLinks';
import AppUsers from './AppSettings/Users';
import AppRegister from './AppSettings/Register';
import AppCompany from './AppSettings/Company';
import AppCompanyEdit from './AppSettings/EditCompany';
import AppService from './AppSettings/Service';
import AppServiceEdit from './AppSettings/EditService';
import AppRemote from './AppSettings/RemoteDevice';
import AppRemoteEdit from './AppSettings/EditRemoteDevice';
import AppVersions from './AppSettings/Versions';
import AppVErsionEdit from './AppSettings/EditVersion';
import AppBranch from './AppSettings/Branch';
import AppBranchEdit from './AppSettings/EditBranch';
import AppBusiness from './AppSettings/Businesses';
import AppBusinessEdit from './AppSettings/EditBusiness';
import AppAllApplications from './AppSettings/AllApplications';
import AppAllApplicationsEdit from './AppSettings/EditAllApplications';
import AppMapping from './AppSettings/Mapping';
import AppMappingEdit from './AppSettings/EditMapping';

import MirrorKPIs from "./Specular/KPIs"
import MirrorStatistics from "../components/Graph"
import MirrorFeedback from "./Specular/Feedback"
import MirrorAdverts from "./Specular/Advertisements"
import MirrorAdvertsEdit from "./Specular/EditAdvertisement"
import MirrorLog from "./Specular/Log"
import MirrorLayout from './Specular/MirrorLayout';
import MirrorApplications from "./Specular/Applications"
import MirrorApplicationEdit from "./Specular/EditApplication"
import PmsDashboard from './Dashboard/PmsDashboard';
import { ServiceCallbackHandler } from './AppSettings/ServiceCallbackHandler';
import CVCategory from './SpecularServices/CVCategory';
import EditCVCategory from './SpecularServices/EditCVCategory';
import CVItems from './SpecularServices/CVItems';
import EditCVItem from './SpecularServices/EditCVItem';


//------ Route Definitions --------
// eslint-disable-next-line no-unused-vars
export const RoutedContent = () => {
    return (
        <Switch>
            <Redirect from="/" to="/specular/dashboard" exact />

            { /*    Pages Routes    */ }
            <PrivateRoute component={ Error404 } path="/pages/error-404" />
            <Route component={ Login } path="/pages/login" />

            <PrivateRoute exact path="/app/files" component={AppFileManager} />
            <PrivateRoute exact path="/app/links" component={AppLinkShorten} />
            <PrivateRoute exact path="/app/users" component={AppUsers} />
            <PrivateRoute exact path="/app/users/add" component={AppRegister} />
            <PrivateRoute exact path="/app/users/edit/:tracker" component={AppRegister} />
            <PrivateRoute exact path="/app/company" component={AppCompany} />
            <PrivateRoute exact path="/app/company/add" component={AppCompanyEdit} />
            <PrivateRoute exact path="/app/company/edit/:tracker" component={AppCompanyEdit} />
            <PrivateRoute exact path="/app/service" component={AppService} />
            <PrivateRoute exact path="/app/service/callback/:service" component={ServiceCallbackHandler} />
            <PrivateRoute exact path="/app/service/add" component={AppServiceEdit} />
            <PrivateRoute exact path="/app/service/edit/:tracker" component={AppServiceEdit} />
            <PrivateRoute exact path="/app/remote" component={AppRemote} />
            <PrivateRoute exact path="/app/remote/add" component={AppRemoteEdit} />
            <PrivateRoute exact path="/app/remote/edit/:tracker" component={AppRemoteEdit} />
            <PrivateRoute exact path="/app/versions" component={AppVersions} />
            <PrivateRoute exact path="/app/versions/add" component={AppVErsionEdit} />
            <PrivateRoute exact path="/app/branch" component={AppBranch} />
            <PrivateRoute exact path="/app/branch/add" component={AppBranchEdit} />
            <PrivateRoute exact path="/app/branch/edit/:tracker" component={AppBranchEdit} />
            <PrivateRoute exact path="/app/business" component={AppBusiness} />
            <PrivateRoute exact path="/app/business/add" component={AppBusinessEdit} />
            <PrivateRoute exact path="/app/business/edit/:tracker" component={AppBusinessEdit} />
            <PrivateRoute exact path="/app/applications" component={AppAllApplications} />
            <PrivateRoute exact path="/app/applications/add" component={AppAllApplicationsEdit} />
            <PrivateRoute exact path="/app/applications/edit/:tracker" component={AppAllApplicationsEdit} />
            <PrivateRoute exact path="/app/mapping" component={AppMapping} />
            <PrivateRoute exact path="/app/mapping/add" component={AppMappingEdit} />
            <PrivateRoute exact path="/app/mapping/edit/:tracker" component={AppMappingEdit} />

            <PrivateRoute exact path="/cv/categories" component={CVCategory} />
            <PrivateRoute exact path="/cv/categories/add" component={EditCVCategory} />
            <PrivateRoute exact path="/cv/categories/edit/:tracker" component={EditCVCategory} />
            <PrivateRoute exact path="/cv/items" component={CVItems} />
            <PrivateRoute exact path="/cv/items/add" component={EditCVItem} />
            <PrivateRoute exact path="/cv/items/edit/:tracker" component={EditCVItem} />

            <PrivateRoute exact path="/specular/kpi" component={MirrorKPIs} />
            <PrivateRoute exact path="/specular/kpi/statistics" component={MirrorStatistics} />
            <PrivateRoute exact path="/specular/feedback" component={MirrorFeedback} />
            <PrivateRoute exact path="/specular/ads" component={MirrorAdverts} />
            <PrivateRoute exact path="/specular/ads/add" component={MirrorAdvertsEdit} />
            <PrivateRoute exact path="/specular/ads/edit/:tracker" component={MirrorAdvertsEdit} />
            <PrivateRoute exact path="/specular/logs" component={MirrorLog} />
            <PrivateRoute exact path="/specular/layout" component={MirrorLayout} />
            <PrivateRoute exact path="/specular/layout/app/:app" component={MirrorLayout} />
            <PrivateRoute exact path="/specular/apps" component={MirrorApplications} />
            <PrivateRoute exact path="/specular/apps/add" component={MirrorApplicationEdit} />
            <PrivateRoute exact path="/specular/apps/edit/:tracker" component={MirrorApplicationEdit} />

            <PrivateRoute exact path="/specular/dashboard" component={PmsDashboard} />

            { /*    404    */ }
            <Redirect to="/pages/error-404" />
        </Switch>
    );
};