import React from 'react';
import { Nav } from './Nav';
import { Navbar } from './Navbar';
import { SidebarTrigger } from "./SidebarTrigger"
import { NavItem } from "reactstrap"
import { NavbarNavigation } from './NavbarNavigation';
import { NavbarUser } from './NavbarUser';

export const DefaultNavbar = () => { 
    return (
        <Navbar light expand="xs" fluid themed>
            <Nav navbar>
                <NavItem className="mr-3">
                    <SidebarTrigger />
                </NavItem>
            </Nav>

            <NavbarNavigation />

            <Nav navbar className="">
                <NavbarUser className="ml-2" />
            </Nav>
        </Navbar>
    );
}
