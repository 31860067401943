import React from 'react';
import PropTypes from 'prop-types';

import {  
    Card, 
    CardBody,
    Badge
} from 'reactstrap';

import {
    TinyDonutChart
} from "./TinyDonutChart"
import {
    TinyBarChart
} from "./TinyBarChart"

const caret = [
    "down",
    "up"
];

const CardSystem = (props) => (
    <Card className="mb-3 mb-lg-0">
       <CardBody className="pb-0">
           <div className="d-flex">
               <span>
                    <Badge pill className="mb-3" color={ props.badgeColor } >
                        <i className={` fa fa-fw fa-caret-${ props.caret !== undefined ? props.caret : "up" }`} />
                        { props.percent !== undefined ? props.percent : 0 }%
                    </Badge>
                    <h6 className="mb-0">
                        { props.title }
                    </h6>
                    <h2 className="mb-3">
                        { props.value !== undefined ? props.value : 0 } <small>{ props.unit }</small>
                    </h2>
                </span>
                <span className="text-right ml-auto">
                    <TinyDonutChart 
                        pieColor="#DEE2E6"
                        pieBg='#5D636D'
                        strokeColor='#5D636D'
                        percent={ props.value !== undefined ? props.value : 0 }
                    />
                </span>
            </div>
            <TinyBarChart />
       </CardBody>
    </Card>
);

CardSystem.propTypes = {
    title: PropTypes.node,
    badgeColor: PropTypes.string,
    unit: PropTypes.node,
    pieColor: PropTypes.string
};
CardSystem.defaultProps = {
    title: "Waiting...",
    badgeColor: "secondary",
    unit: "%",
    pieColor: "500"
};

export { CardSystem };
