import { ToastContainer } from 'react-toastify';
import {
    Route, Switch
} from 'react-router-dom';

import Layout from "./Layout";
import { ThemeProvider } from "./Theme";
import { DefaultNavbar } from './Navbar/DefaultNavbar';
import { DefaultSidebar } from './Navbar/DefaultSidebar';

import './../styles/bootstrap.scss';
import './../styles/main.scss';
import './../styles/plugins/plugins.scss';
import './../styles/plugins/plugins.css';

function AppLayout({children}) {
    return (
        <ThemeProvider initialStyle="light" initialColor="danger">
            <Layout sidebarSlim>
            { /* --------- Navbar ----------- */ }
                    <Layout.Navbar>
                        <DefaultNavbar />
                    </Layout.Navbar>
                    { /* -------- Sidebar ------------*/ }
                    <Layout.Sidebar>
                        <DefaultSidebar />
                    </Layout.Sidebar>

                    { /* -------- Content ------------*/ }
                    <Layout.Content>
                        { children }
                    </Layout.Content>

                    <ToastContainer 
                        position='top-right'
                        autoClose={7000}
                        draggable={false}
                        hideProgressBar={true}
                        //theme="light"
                        icon={false}
                    />

                    { /* -- Theme Selector (DEMO) ----*/ }
                    {/*<PageConfigConsumer>
                    {
                        ({ sidebarHidden, navbarHidden }) => (
                            <ThemeSelector styleDisabled={ sidebarHidden && navbarHidden } />
                        )
                    }
                    </PageConfigConsumer>*/}
            </Layout>
        </ThemeProvider>
    )
}

export default AppLayout;