import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Sidebar from './Sidebar';
import { SidebarTrigger } from './SidebarTrigger';
import { Card, CardBody, CardHeader, DropdownItem, PopoverBody } from 'reactstrap';
import { SidebarMiddleNav } from './SidebarMiddleNav';

import { SidebarBottomA } from './SidebarBottomA'
import { LogoThemed } from '../AppInfo/LogoThemed';
import { prepareSystemServiceQuery, useSocket } from "../../contexts/SocketContext"
import { useSelectedService } from '../../contexts/SelectedServiceContext';
import { Dropdown } from '../Dropdown';

export const DefaultSidebar = () => {
    const { socket } = useSocket()
    const [services, setServices] = useState([]);
    const { selectedService, setSelectedService } = useSelectedService();

    //When the application changes, load the available services on that application type
    useEffect(() => {
        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "remote"))
                .then(remotes => {
                    setServices(remotes.response);
                })
        }
    }, [socket])

    return (
        <Sidebar>
            { /* START SIDEBAR-OVERLAY: Close (x) */}
            <Sidebar.Close>
                <SidebarTrigger tag={'a'} href="#">
                    <i className="fa fa-times-circle fa-fw"></i>
                </SidebarTrigger>
            </Sidebar.Close>
            { /* START SIDEBAR-OVERLAY: Close (x) */}

            { /* START SIDEBAR: Only for Desktop */}
            <Sidebar.HideSlim>
                <Sidebar.Section>
                    <Link to="/" className="sidebar__brand">
                        <LogoThemed checkBackground newColor="primary" />
                    </Link>
                </Sidebar.Section>
            </Sidebar.HideSlim>
            { /* END SIDEBAR: Only for Desktop */}

            { /* START SIDEBAR: Only for Mobile */}
            <Sidebar.MobileFluid>
                <Sidebar.Section fluid cover>
                    { /* SIDEBAR: Menu */}
                    <SidebarMiddleNav />
                </Sidebar.Section>

                <Sidebar.HideSlim>
                    <Sidebar.Section style={{padding: 0}}>
                        <PopoverBody>
                            Server Connection: &nbsp;
                            {socket !== null && socket.isOpened === true ? <span style={{ color: "green" }}>Connected</span> : <span style={{ color: "red" }}>Disconnected</span>}
                        </PopoverBody>
                    </Sidebar.Section>
                </Sidebar.HideSlim>

                <SidebarBottomA />
            </Sidebar.MobileFluid>
            { /* END SIDEBAR: Only for Mobile */}
        </Sidebar>
    );
}