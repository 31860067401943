import React from 'react';
import PropTypes from 'prop-types';

import { 
    CardTitle,
    Badge,
    Card,
    CardBody
} from 'reactstrap';

const SimpleOverviewCard = (props) => (
    <Card className="mb-3" style={{minHeight: "242px"}}>
        <CardBody>
            <div className="d-flex">
                <CardTitle tag="h3" style={{margin: "0"}}>
                    { props.title }
                </CardTitle>
                <Badge pill color={ `${ props.badgeColor }` } className="align-self-center ml-auto" style={{padding: "0.4rem", margin: "0"}}>
                    { props.badgeTitle }
                </Badge>
            </div>
            <div className="text-center my-4">
                <h1>{ props.value }</h1>
                <h5>
                    { props.valueTitle }
                </h5>
            </div>
            <div className="d-flex">
                <span>
                    { props.footerTitle }
                </span>
                <span className={ `ml-auto ${ props.footerTitleClassName }` }>
                    <i className={ `fa mr-1 fa-${ props.footerIcon }` }></i>
                    { props.footerValue }
                </span>
            </div>
        </CardBody>
    </Card>
)
SimpleOverviewCard.propTypes = {
    title: PropTypes.node,
    badgeColor: PropTypes.node,
    badgeTitle: PropTypes.node,
    value: PropTypes.node,
    valueTitle: PropTypes.node,
    footerTitle: PropTypes.node,
    footerTitleClassName: PropTypes.node,
    footerIcon: PropTypes.node,
    footerValue: PropTypes.node
};
SimpleOverviewCard.defaultProps = {
    title: "Waiting",
    badgeColor: "secondary",
    badgeTitle: "Waiting",
    value: "0.000",
    valueTitle: "Waiting",
    footerTitle: "Waiting",
    footerTitleClassName: "text-muted",
    footerIcon: "caret-down",
    footerValue: "0.00%"
};

export { SimpleOverviewCard };