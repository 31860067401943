import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
    Input as RSInput,
    FormGroup,
    FormFeedback,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import PageLoad from '../../components/PageLoad';
import ReactSelect from '../../components/AdvancedSelect';
import _ from 'lodash';

function General(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [mapsArr, setMapsArr] = useState([{ layoutMap: "", routeMap: "", rasterMap: "", type: "building", floor: "" }])
    
    const [branch, setBranch] = useState("")
    const [availableBranches, setAvailableBranches] = useState([])

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];
        setDataLoading(false)
        if (socket !== null && socket.isOpened) {
            setDataLoading(true)
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "mapping")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch"))
            ]).then(([mapInfo, companiesInfo, branchesInfo]) => {
                console.log(companiesInfo)
                console.log(branchesInfo)
                if (mapInfo.response !== undefined) {
                    mapInfo.response.forEach(item => {
                        if (item._id === ruleObj) {
                            setIsEdit(true)
                            setEditId(item._id)
                            if(item.branch) setBranch(item.branch._id);
                            if(item.maps) setMapsArr(item.maps);

                            
                            setDataLoading(false)
                        }
                    })
                }
                var branches = []
                companiesInfo.response.forEach(company => {
                    branchesInfo.response.forEach(branch => {
                        if(branch.company === company._id) {
                            branches = [...branches, branch]
                        }
                    })
                })
                setAvailableBranches(branches)
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
            
        }
    }, [socket]);

    function handleAddInput(array, setMethod) {
        const values = [...array];
        values.push({ layoutMap: "", routeMap: "", rasterMap: "", type: "building", floor: "" });
        setMethod(values);
    }

    function handleRemoveInput(index, array, setMethod) {
        const values = [...array];
        values.splice(index, 1);
        setMethod(values);
    }

    function handleChangeInput(i, event, array, setMethod) {
        const values = [...array];
        const { name, value } = event.target;
        values[i][name] = value;
        setMethod(values);
    }

    function handleChangeInputFile(i, event, array, setMethod) {
        const values = [...array];
        const { name, files } = event.target;
        values[i][name] = files[0];
        setMethod(values);
    }

    

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    //Validate that everything is in order
    async function validationInputs() {
        return true;
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            //setMessage("Some fields contain errors");
            return false;
        }
        const promiseArr = []
        mapsArr.forEach((map, index) => {
            if(map.type === "building") {
                if(map.layoutMap !== "") {
                    promiseArr.push(uploadFile(map.layoutMap, `${branch}-${index}-Maps Object`, `${branch}-${index}-maps`))
                }
                if(map.routeMap !== "") {
                    promiseArr.push(uploadFile(map.routeMap, `${branch}-${index}-Routing Object`, `${branch}-${index}-routes`))
                }
                if(map.rasterMap !== "") {
                    promiseArr.push(uploadFile(map.rasterMap, `${branch}-${index}-Map Raster Object`, `${branch}-${index}-raster`))
                }
            } else if(map.type === "parking") {
                if(map.layoutMap !== "") {
                    promiseArr.push(uploadFile(map.layoutMap, `${branch}-${index}-Parking Maps Object`, `${branch}-${index}-parkingMaps`))
                }
                if(map.routeMap !== "") {
                    promiseArr.push(uploadFile(map.routeMap, `${branch}-${index}-Parking Routing Object`, `${branch}-${index}-parkingRoutes`))
                }
                if(map.rasterMap !== "") {
                    promiseArr.push(uploadFile(map.rasterMap, `${branch}-${index}-Parking Map Raster Object`, `${branch}-${index}-parkingRaster`))
                }
            }
        })

        if(promiseArr.length === 0) {
            updateData(undefined)
        } else {
            Promise.all(promiseArr)
            .then((res) => {
                var newMapObj = [...mapsArr]
                for(var i = 0; i < res.length; i++) {
                    const item = res[i].reference.split("-")
                    if(item[2] === "maps" || item[2] === "parkingMaps") {
                        newMapObj[item[1]].layoutMap = res[i].id
                    }
                    if(item[2] === "routes" || item[2] === "parkingRoutes") {
                        newMapObj[item[1]].routeMap = res[i].id
                    }
                    if(item[2] === "raster" || item[2] === "parkingRaster") {
                        newMapObj[item[1]].rasterMap = res[i].id
                    }
                }
                updateData(newMapObj)
            })
        }
    }

    function uploadFile(file, name, reference) {
        return new Promise ((resolve, reject) => {
            const formData = new FormData();
            let headers = new Headers();

            formData.append('file', file);
            formData.append("title", name);
            formData.append("description", name);

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);
            fetch(`${localStorage.getItem("serverIp")}/file`, {
                method: 'POST',
                headers: headers,
                body: formData,
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success) {
                    console.log(res)
                    resolve({id: res.file._id, reference: reference})
                } else {
                    reject(res.message)
                }
            })
            .catch((error) => {
                reject(error)
            });
        })
        
    }

    function updateData(newMapObj) {
        //Creates the final rule based on the prepared alias names
        var finalRule = {
            branch: branch,
            maps: newMapObj
        };

        if (isEdit) {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                finalRule.id = editId
                socket.sendRequest(prepareSystemServiceQuery("PUT", "mapping", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
        else {
            //Create rule and redirect on success
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("POST", "mapping", finalRule)).then((res) => {
                    console.log(res)
                    if (res.response.error === undefined) {
                        toast.success(contentSuccess);
                        setOperation(true);
                    } else {
                        setMessage(res.response.error)
                    }
                });
            }
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/mapping" />;
    }

    if (dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="General"
                        subTitle="Update information regaring your branch's mapping"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="hotel" id="hotelLabel" sm={3}>
                                        Branch
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={branch}>
                                            {
                                                availableBranches.map(availableHotel => (
                                                    <DropdownItem key={availableHotel._id} onClick={(e) => setBranch(availableHotel._id)}>
                                                        {availableHotel.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="hotel" id="hotelLabel" sm={3}>
                                        Add Maps Floor
                                    </Label>
                                    <Col sm={9}>
                                        <Button color="success" onClick={() => { handleAddInput(mapsArr, setMapsArr) }}>+</Button>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    {
                        mapsArr.map((entry, index) => (
                            <Card className="mb-3" key={index}>
                                <CardHeader style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
                                    Map {index} 
                                    <Button color="danger" outline onClick={() => { handleRemoveInput(index, mapsArr, setMapsArr) }}>
                                        <i className="fa fa-remove"></i>
                                    </Button>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <FormRow>
                                            <Label for="floor" id="floorLabel" sm={3}>
                                                Floor Name
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="text" id="floor" name="floor" maxLength="100" value={entry.floor} onChange={e => { handleChangeInput(index, e, mapsArr, setMapsArr) }} required />
                                            </Col>
                                        </FormRow>
                                        <FormRow>
                                            <Label for="type" id="typeLabel" sm={3}>
                                                Floor Type
                                            </Label>
                                            <Col sm={9}>
                                                <Dropdown toggle={entry.type}>
                                                    <DropdownItem name="type" value={"building"} onClick={e => { handleChangeInput(index, e, mapsArr, setMapsArr) }} >
                                                        building
                                                    </DropdownItem>
                                                    <DropdownItem name="type" value={"parking"} onClick={e => { handleChangeInput(index, e, mapsArr, setMapsArr) }} >
                                                        parking
                                                    </DropdownItem>
                                                </Dropdown>
                                            </Col>
                                        </FormRow>
                                        <FormRow>
                                            <Label sm={3}>
                                                GeoJSON Layout Map File
                                            </Label>
                                            <Col sm={9}>
                                                <RSInput
                                                    bsSize='sm'
                                                    type="file"
                                                    label={entry.layoutMap || 'choose a geojson file'}
                                                    accept=".json,.geojson"
                                                    name="layoutMap"
                                                    onChange={e => handleChangeInputFile(index, e, mapsArr, setMapsArr)}
                                                />
                                            </Col>
                                        </FormRow>
                                        <FormRow>
                                            <Label sm={3}>
                                                GeoJSON Routing Map File
                                            </Label>
                                            <Col sm={9}>
                                                <RSInput
                                                    bsSize='sm'
                                                    type="file"
                                                    label={entry.routeMap || 'choose a geojson file'}
                                                    name="routeMap"
                                                    accept=".json,.geojson"
                                                    onChange={e => handleChangeInputFile(index, e, mapsArr, setMapsArr)}
                                                    />
                                            </Col>
                                        </FormRow>
                                        <FormRow>
                                            <Label sm={3}>
                                                GeoJSON Raster Map File
                                            </Label>
                                            <Col sm={9}>
                                                <RSInput
                                                    bsSize='sm'
                                                    type="file"
                                                    label={entry.rasterMap || 'choose a geojson file'}
                                                    name="rasterMap"
                                                    accept=".json,.geojson"
                                                    onChange={e => handleChangeInputFile(index, e, mapsArr, setMapsArr)}
                                                    />
                                            </Col>
                                        </FormRow>
                                    </Form>
                                </CardBody>
                            </Card>
                            
                        ))
                    }
                                
                    
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{"Update"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

General.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default General;

