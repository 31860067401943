import React, { useState, useEffect } from 'react';
import { Redirect, useLocation, useParams } from 'react-router';
import { prepareOAuthAccessQuery, useSocket } from '../../contexts/SocketContext';

export function ServiceCallbackHandler(props) {
    const { socket } = useSocket();
    const { service } = useParams();
    const { search } = useLocation();

    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form

    useEffect(() => {
        const searchParams = new URLSearchParams(search)

        if (socket !== null && socket.isOpened) {
            switch(service) {
                case "google": {
                    socket.sendRequest(prepareOAuthAccessQuery(searchParams.get("state"), searchParams.get("code")))
                    .then(res => {
                        console.log(res)
                        setOperation(true)
                    })
                    break;
                }
                case "ApaleoPMS":
                default: {
                    socket.sendRequest(prepareOAuthAccessQuery(searchParams.get("state"), searchParams.get("code")))
                    .then(res => {
                        console.log(res)
                        setOperation(true)
                    })
                    
                }
            }
        }
    }, [socket])

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/service" />;
    }
    
    return (
        <div style={{ width: "100%", textAlign: "center", display: "flex", justifyContent: "center" }}><div className="spinner" /></div>
    )
}