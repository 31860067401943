import React from "react";
import PropTypes from 'prop-types';
import { InputGroup } from "reactstrap";

export const GboxInputGroup = ({children, ...props}) => {
    return (
        <InputGroup
            { ...props }
        >
            { children }
        </InputGroup>
    );
}

GboxInputGroup.propTypes = {
    size: PropTypes.string,
    children: PropTypes.node
}

GboxInputGroup.defaultProps = {
    size: 'sm'
}