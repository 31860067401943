import React from "react";
import PropTypes from 'prop-types';
import { FormGroup } from "reactstrap";

export const GboxFormRow = ({children, className, ...props}) => {
    return (
        <FormGroup row className={className + " align-items-center"}
            { ...props }
        >
            { children }
        </FormGroup>
    );
}

GboxFormRow.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
}

GboxFormRow.defaultProps = {
    className: ""
}