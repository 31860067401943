import React from "react";
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";

export const Dropdown = ({ children, toggle, caret, menuoptions, ...props }) => {
    return (
        <UncontrolledButtonDropdown disabled={props.disabled}
            {...props} 
        >
            <DropdownToggle caret={caret} outline >
                {toggle}
            </DropdownToggle>
            <DropdownMenu style={props.menustyle} {...menuoptions}>
                {children}
            </DropdownMenu>

        </UncontrolledButtonDropdown>
    );
}

Dropdown.propTypes = {
    size: PropTypes.string,
    toggle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    caret: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    menustyle: PropTypes.object,
    menuoptions: PropTypes.object
}

Dropdown.defaultProps = {
    size: 'sm',
    toggle: "",
    caret: false,
    disabled: false,
    menustyle: {}
}