import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    Input as RSInput,
    InputGroupAddon,
    InputGroupText
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import PageLoad from '../../components/PageLoad';

function EditCompany(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [title, setTitle] = useState("")
    const [internalId, setInternalId] = useState("")
    const [color, setColor] = useState("")
    const [defaultWidth, setDefaultWidth] = useState("")
    const [defaultHeight, setDefaultHeight] = useState("")

    const [appParams, setAppParams] = useState([{label: "", paramType: "string", paramName: ""}])

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "applications")).then(res => {
                res.response.forEach(item => {
                    if(item._id === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        setTitle(item.title);
                        setInternalId(item.internalId);
                        setColor(item.color);
                        setDefaultWidth(item.defaultWidth);
                        setDefaultHeight(item.defaultHeight);
                        if(item.appParams && item.appParams.length > 0) {
                            for (let index = 0; index < item.appParams.length; index++) {
                                if(item.appParams[index].paramType === "array") {
                                    item.appParams[index].paramName = JSON.stringify(item.appParams[index].paramName)
                                }
                                if(item.appParams[index].paramType === "bool") {
                                    item.appParams[index].paramName = item.appParams[index].paramName + ""
                                }
                            }
                            setAppParams([...item.appParams, {label: "", paramType: "string", paramName: ""}])
                        }
                        
                        setDataLoading(false);
                    }
                })
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    function handleChangeInput(i, event, array, setMethod) {
        const values = [...array];
        const { name, value } = event.target;
        values[i][name] = value;
        setMethod(values);
    }

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            setMessage("Some fields contain errors");
            return false;
        }

        //Creates the final rule based on the prepared alias names
        var finalRule = {
            title: title,
            internalId: internalId,
            color: color,
            defaultWidth: defaultWidth,
            defaultHeight: defaultHeight,
            appParams: appParams.filter(val => val.label !== "")
        };
        
        for(var i = 0; i < finalRule.appParams.length; i++) {
            if (finalRule.appParams[i].paramType === "array") {
                //console.log("Converting " + finalRule.appParams[i].label + " to array")
                finalRule.appParams[i].paramName = JSON.parse(finalRule.appParams[i].paramName)
                //console.log(finalRule.appParams[i])
            }
            if (finalRule.appParams[i].paramType === "bool") {
                //console.log("Converting " + finalRule.appParams[i].label + " to bool")
                finalRule.appParams[i].paramName = finalRule.appParams[i].paramName === 'true'
                //console.log(finalRule.appParams[i])
            }
        }

        if (isEdit) {
            finalRule.id = editId
        }
        console.log(finalRule)
        //Create rule and redirect on success
        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("PUT", "applications", finalRule)).then((res) => {
                console.log(res)
                if (res.response.error === undefined) {
                    toast.success(contentSuccess);
                    setOperation(true);
                } else {
                    setMessage(res.response.error)
                }
            });
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new company. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/applications" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Add an Application"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        App Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={title} onChange={e => setTitle(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="id" id="idLabel" sm={3}>
                                        Internal ID <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="id" maxLength="52" value={internalId} onChange={e => setInternalId(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="color" id="colorLabel" sm={3}>
                                        App Color <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="color" maxLength="10" value={color} onChange={e => setColor(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="width" id="widthLabel" sm={3}>
                                        Default Width <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="number" id="width" maxLength="52" value={defaultWidth} onChange={e => setDefaultWidth(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="height" id="heightLabel" sm={3}>
                                        Default Height <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="number" id="height" maxLength="52" value={defaultHeight} onChange={e => setDefaultHeight(e.target.value)} required />
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader>
                            Available Parameters
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    {appParams.map((item, index) => (
                                        <Col sm={12} key={index}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Parameter Label</InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" name="label" maxLength="52" value={item.label} onChange={e => {
                                                    handleChangeInput(index, e, appParams, setAppParams)
                                                    if((e.target.value.length > 0 || item.paramName.length > 0)) {
                                                        if(appParams[index + 1] === undefined) {
                                                            setAppParams([...appParams, {label: "", paramType: "string", paramName: ""}])
                                                        }
                                                        
                                                    } else {
                                                        setAppParams(appParams.slice(0, -1))
                                                    }
                                                }} />
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Type</InputGroupText>
                                                </InputGroupAddon>
                                                <Dropdown toggle={item.paramType}>
                                                    <DropdownItem name="paramType" value={"string"}  onClick={e => handleChangeInput(index, e, appParams, setAppParams)}>String</DropdownItem>
                                                    <DropdownItem name="paramType" value={"bool"}  onClick={e => handleChangeInput(index, e, appParams, setAppParams)} >Boolean</DropdownItem>
                                                    <DropdownItem name="paramType" value={"array"}  onClick={e => handleChangeInput(index, e, appParams, setAppParams)} >Array</DropdownItem>
                                                </Dropdown>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Parameter Value</InputGroupText>
                                                </InputGroupAddon>
                                                {
                                                    (item.paramType === "string" || item.paramType === "array") &&
                                                    <Input type="text" name="paramName" value={item.paramName} onChange={e => {
                                                        handleChangeInput(index, e, appParams, setAppParams)
                                                        if((e.target.value.length > 0 || item.label.length > 0)) {
                                                            if(appParams[index + 1] === undefined) {
                                                                setAppParams([...appParams, {label: "", paramType: "string", paramName: ""}])
                                                            }
                                                            
                                                        } else {
                                                            setAppParams(appParams.slice(0, -1))
                                                        }
                                                    }} />
                                                }
                                                {
                                                    item.paramType === "bool" &&
                                                    <Dropdown toggle={item.paramName}>
                                                        <DropdownItem name="paramName" value={true}  onClick={e => handleChangeInput(index, e, appParams, setAppParams)}>True</DropdownItem>
                                                        <DropdownItem name="paramName" value={false}  onClick={e => handleChangeInput(index, e, appParams, setAppParams)} >False</DropdownItem>
                                                    </Dropdown>
                                                }
                                                
                                            </InputGroup>
                                        </Col>
                                    ))}
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

EditCompany.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditCompany;

