import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    CardHeader,
    DropdownItem,
    Media
} from 'reactstrap';
import { ThemeConsumer } from "../../components/Theme"
import { HeaderMain } from "../../components/HeaderMain";
import { prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';
import { Button, Dropdown, FormRow } from '../../components';
import { toast } from 'react-toastify';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { FaCog, FaTableTennis, FaTimes } from "react-icons/fa"
import ConfirmationModal from '../../components/ConfirmationModal';
import { useHistory } from 'react-router-dom';

const ResponsiveGridLayout = WidthProvider(Responsive);
function MirrorLayout() {
    const { socket } = useSocket();
    const history = useHistory();

    const [selectedAppToAdd, setSelectedAppToAdd] = useState("Choose Application");
    const [screen, setScreen] = useState(1);
    const [mirrorApplications, setMirrorApplications] = useState([])
    const [company, setCompany] = useState("")

    const [message, setMessage] = useState("");

    /*const applications = [
        { "name": "specular_clock", "title": "Clock", "color": "#9CE81A", "default_dimensions": { "width": 200, "height": 200 } },
        { "name": "specular_stock_ticker", "title": "Stock Information", "color": "#171B9A", "default_dimensions": { "width": 200, "height": 200 } },
        { "name": "specular_daily_sentence", "title": "Daily Sentence", "color": "#BDBEEE", "default_dimensions": { "width": 400, "height": 200 } },
        { "name": "specular_news", "title": "News Feed", "color": "#C2B244", "default_dimensions": { "width": 250, "height": 400 } },
        { "name": "specular_label", "title": "Special Label", "color": "#7CF680", "default_dimensions": { "width": 300, "height": 200 } },
        { "name": "specular_weather", "title": "Weather Forecast", "color": "#E24493", "default_dimensions": { "width": 350, "height": 230 } },
        { "name": "specular_todo_list", "title": "ToDo List", "color": "#171B9A", "default_dimensions": { "width": 250, "height": 400 } },
        { "name": "specular_weight_tracker", "title": "Weight Tracker", "color": "#171B9A", "default_dimensions": { "width": 250, "height": 400 } },
        { "name": "specular_radio", "title": "Radio Player", "color": "#171B9A", "default_dimensions": { "width": 200, "height": 200 } },
        { "name": "specular_youtube_player", "title": "Youtube Player", "color": "#E24493", "default_dimensions": { "width": 350, "height": 200 } },
        { "name": "specular_alarm_clock", "title": "Alarm Clock", "color": "#171B9A", "default_dimensions": { "width": 250, "height": 400 } },
        { "name": "specular_spotify", "title": "Spotify Player", "color": "#171B9A", "default_dimensions": { "width": 250, "height": 400 } },
        { "name": "specular_flight_tracker", "title": "Flight Tracker", "color": "#171B9A", "default_dimensions": { "width": 330, "height": 466 } },
        { "name": "specular_welcome", "title": "Specular Welcome", "color": "#00FF00", "default_dimensions": { "width": 400, "height": 200 } },
        { "name": "specular_rastaurant", "title": "Restaurant", "color": "#F44336", "default_dimensions": { "width": 200, "height": 200 } },
        { "name": "specular_promotions", "title": "Promotions", "color": "#7CF680", "default_dimensions": { "width": 500, "height": 250 } },
        { "name": "specular_browser", "title": "Browser", "color": "#171B9A", "default_dimensions": { "width": 800, "height": 700 } },
        { "name": "specular_taxi", "title": "Order Taxi", "color": "#9CE81A", "default_dimensions": { "width": 200, "height": 200 } },
        { "name": "specular_notes", "title": "Notes", "color": "#E24493", "default_dimensions": { "width": 350, "height": 350 } },
        { "name": "specular_maintenance", "title": "Maintenances", "color": "#171B9A", "default_dimensions": { "width": 350, "height": 230 } },
        { "name": "specular_places", "title": "Places", "color": "#171B9A", "default_dimensions": { "width": 400, "height": 700 } },
        { "name": "specular_ads", "title": "Ads", "color": "#7CF680", "default_dimensions": { "width": 500, "height": 250 } }
    ]*/

    const [applications, setApplications] = useState([])
    const [branches, setBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState("")

    const [availableLayouts, setAvailableLayouts] = useState([])
    const [selectedLayoutId, setSelectedLayoutId] = useState("")

    useEffect(() => {
        if (socket !== null && socket.isOpened) {
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch/applications")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "applications")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "mirrorlayout"))
            ])
            //socket.sendRequest(prepareSystemServiceQuery("GET", "mirrorlayout", {id: parseJwt(localStorage.getItem("wsToken")).company[0]}))
            .then(([branchApps, allApps, branchesInfo, mirrorLayout]) => {
                setAvailableLayouts(mirrorLayout.response)
                var mirrorApps = []
                branchApps.response.forEach(app => {
                    var newAppData = {}
                    if(app.appParams) {
                        for (let i = 0; i < app.appParams.length; i++) {
                            const element = app.appParams[i];
                            const obj = {
                                [element.label]: element.paramName,
                            }
                            newAppData = {...newAppData, ...obj}
                        }
                    }
                    newAppData = {...newAppData, ...(app.appData !== undefined ? app.appData : {})}

                    mirrorApps = [...mirrorApps, {
                        ref: app._id,
                        name: app.type.internalId,
                        title: app.title,
                        x: 0,
                        y: 0,
                        color: app.type.color,
                        w: app.type.defaultWidth,
                        h: app.type.defaultHeight,
                        category: "customized",
                        appData: newAppData
                    }]
                })
                allApps.response.forEach(app => {
                    var newAppData = {}
                    if(app.appParams) {
                        for (let i = 0; i < app.appParams.length; i++) {
                            const element = app.appParams[i];
                            const obj = {
                                [element.label]: element.paramName,
                            }
                            newAppData = {...newAppData, ...obj}
                        }
                    }
                    newAppData = {...newAppData, ...(app.appData !== undefined ? app.appData : {})}
                    mirrorApps = [...mirrorApps, {
                        ref: app._id,
                        name: app.internalId,
                        title: app.title,
                        x: 0,
                        y: 0,
                        color: app.color,
                        w: app.defaultWidth,
                        h: app.defaultHeight,
                        category: "builtIn",
                        appData: newAppData
                    }]
                })
                setApplications(mirrorApps)
                setBranches(branchesInfo.response)
            })
        }
    }, [socket]);

    function generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    function handleLayoutChange(layoutObjects) {
        layoutObjects.forEach(e => {
            for (let i = 0; i < mirrorApplications.length; i++) {
                if(e.i === mirrorApplications[i].uuid) {
                    mirrorApplications[i].x = e.x
                    mirrorApplications[i].y = e.y
                    mirrorApplications[i].w = e.w
                    mirrorApplications[i].h = e.h
                }
            }
        })
    }

    function addApplicationToMirror() {
        applications.forEach(app => {
            if (app.title === selectedAppToAdd) {
                setMirrorApplications([...mirrorApplications, {
                    uuid: generateUUID(),
                    name: app.name,
                    title: app.title,
                    x: 0,
                    y: 0,
                    color: app.color,
                    w: parseInt(app.w / 20),
                    h: parseInt(app.h / 10),
                    category: app.category,
                    ref: app.ref,
                    appData: app.appData
                }])
            }
        })
    }

    function handleChangeBranch(branchId, screen) {
        setSelectedBranch(branchId)
        setSelectedLayoutId('')
        setScreen(screen)
        var found = false
        var mirrorApps = []
        availableLayouts.forEach(layout => {
            if(!found && layout.branch === branchId && (layout.layout ? layout.layout === screen : true)) {
                found = true
                setSelectedLayoutId(layout._id)
                mirrorApps = [...layout.builtInApps.map(app => ({
                    uuid: app.uuid,
                    name: app.ref.internalId,
                    title: app.ref.title,
                    x: app.transformX,
                    y: app.transformY,
                    color: app.ref.color,
                    w: app.transformWidth,
                    h: app.transformHeight,
                    
                    category: "builtIn",
                    ref: app.ref._id,
                    appData: app.appData
                })), ...layout.customizedApps.map(app => ({
                    uuid: app.uuid,
                    name: applications.filter(appCatalog => appCatalog.ref === app.ref.type)[0].name,
                    title: app.ref.title,
                    x: app.transformX,
                    y: app.transformY,
                    color: applications.filter(appCatalog => appCatalog.ref === app.ref.type)[0].color,
                    w: app.transformWidth,
                    h: app.transformHeight,
                    
                    category: "customized",
                    ref: app.ref._id,
                    appData: app.appData
                }))]
            }
        })
        
        setMirrorApplications(mirrorApps)
    }

    async function updateLayout() {
        return new Promise((resolve, reject) => {
            if (socket !== null && socket.isOpened) {
                var finalRule = {
                    branch: selectedBranch,
                    layout: screen,
                    builtInApps: mirrorApplications.filter(app => app.category === "builtIn").map(app => ({
                        uuid: app.uuid,
                        transformX: app.x,
                        transformY: app.y,
                        transformWidth: parseInt(app.w),
                        transformHeight: parseInt(app.h),
                        ref: app.ref,
                        appData: app.appData
                    })),
                    customizedApps: mirrorApplications.filter(app => app.category === "customized").map(app => ({
                        uuid: app.uuid,
                        transformX: app.x,
                        transformY: app.y,
                        transformWidth: parseInt(app.w),
                        transformHeight: parseInt(app.h),
                        ref: app.ref,
                        appData: app.appData
                    }))
                }
    
                if(selectedLayoutId !== "") {
                    finalRule.id = selectedLayoutId
                }
    
                socket.sendRequest(prepareSystemServiceQuery("PUT", "mirrorlayout", finalRule))
                .then((res) => {
                    if (res.response.error === undefined) {
                        socket.sendRequest({ request_type: "Remotes", uri: "layout", data: {branch: selectedBranch, layout: screen} }).then((res) => {
                            if(res.response !== undefined) {
                                console.log(res.response)
                            }
                            toast.success(contentSuccess);
                            resolve()
                        })
                    } else {
                        setMessage(res.response.error)
                        reject()
                    }
                });
            }
        })
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Customize Layout"
                        className="mb-4 mb-lg-5"
                    />
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                
                                <FormRow>
                                    <Label for="hotel" id="hotelLabel" sm={3}>
                                        Set at which branch the applications will be visible
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={selectedBranch}>
                                            {
                                                branches.map(availableHotel => (
                                                    <DropdownItem key={availableHotel._id} onClick={(e) => handleChangeBranch(availableHotel._id, screen)}>
                                                        {availableHotel.name}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="screen" id="screenLabel" sm={3}>
                                        Set the screen number for this layout
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={screen}>
                                            {
                                                Array.from({length: 10}, () => 0).map((a, i) => (
                                                    <DropdownItem key={i+1} onClick={(e) => handleChangeBranch(selectedBranch, i+1)}>
                                                        {i+1}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="availableApplications" id="availableApplicationsLabel" sm={3}>
                                        Add application to mirror
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={selectedAppToAdd} menustyle={{ maxHeight: "300px", overflowY: "scroll" }}>
                                            <DropdownItem text><b>Customized Applications</b></DropdownItem>
                                            {
                                                applications.filter(app => app.category === "customized").map((application, i) => (
                                                    <DropdownItem key={`${application.name}-${i}`} onClick={(e) => setSelectedAppToAdd(e.currentTarget.textContent)}>
                                                        {application.title}
                                                    </DropdownItem>
                                                ))
                                            }
                                            <DropdownItem divider />
                                            <DropdownItem text><b>Built In Applications</b></DropdownItem>
                                            {
                                                applications.filter(app => app.category === "builtIn").map(application => (
                                                    <DropdownItem key={application.name} onClick={(e) => setSelectedAppToAdd(e.currentTarget.textContent)}>
                                                        {application.title}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                        <ThemeConsumer>
                                            {
                                                ({ color }) => (
                                                    /* Make color as the same of parent */
                                                    <Button color={color} disabled={selectedAppToAdd === "Choose Application"} outline={false} size="sm" onClick={addApplicationToMirror}>Add</Button>
                                                )
                                            }
                                        </ThemeConsumer>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Col sm={9} style={{marginTop: "10px"}}>
                                        <ThemeConsumer>
                                            {
                                                ({ color }) => (
                                                    /* Make color as the same of parent */
                                                    <Button color={color} outline={false} size="md" onClick={updateLayout}>Update Layout</Button>
                                                )
                                            }
                                        </ThemeConsumer>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card className="grid" style={{ width: "540px", height: "960px" }} >
                        <CardHeader>
                            Mirror View
                        </CardHeader>
                        <ResponsiveGridLayout useCSSTransforms={true} measureBeforeMount={true}
                            style={{ position: "relative" }}
                            onLayoutChange={(e) => { handleLayoutChange(e) }} preventCollision={true}
                            compactType={null} rowHeight={1}
                            margin={[5, 5]}
                            containerPadding={[10, 10]}
                            //breakpoints={{ lg: 1199, md: 996, sm: 768, xs: 480, xxs: 0 }}
                            cols={{ lg: 30, md: 30, sm: 10, xs: 30, xxs: 30 }}
                            isBounded={true}
                            isDraggable={true}
                        >
                            {
                                mirrorApplications.map((app) => (
                                    <div className={'widget'}
                                        key={app.uuid} 
                                        data-grid={{ x: app.x, y: app.y, w: app.w, h: app.h }} 
                                    >
                                        <div className={'controls'}>
                                        <ConfirmationModal 
                                            className="edit" 
                                            buttonDom={<FaCog />} 
                                            heading={"Confirm Save"} 
                                            message={"Changing parameters on a mirror layout application requires saving the layout before proceeding."} 
                                            successFunction={() => {
                                                updateLayout()
                                                .then(() => {
                                                    history.push("layout/app/" + app.uuid)
                                                })
                                            }} 
                                            />
                                        <div className={'delete'} onClick={() =>  setMirrorApplications(mirrorApplications.filter(item => item.uuid !== app.uuid))}>
                                            <FaTimes />
                                        </div>
                                        </div>
                                        <div className={'info'}>
                                        <div className={'icon'}>
                                            {app.icon ? app.icon : <FaTableTennis fontSize={18} />}
                                        </div>
                                        <span className={'type'}>{app.title || 'Broken Widget'}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </ResponsiveGridLayout>
                    </Card>
                </Col>


            </Row>
        </Container>
    );
}
export default MirrorLayout;