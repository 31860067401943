import React, { useState, useEffect, useContext } from 'react';
import {
    Container,
    Row,
    Col,
    CardBody,
    Media,
    Badge,
    Card,
    DropdownItem,
    UncontrolledButtonDropdown,
    DropdownToggle,
    ButtonToolbar,
    DropdownMenu
} from 'reactstrap';
import {
    PageConfigContext
} from "../../components/Layout"
import { HeaderMain } from "../../components/HeaderMain";
import _ from "lodash";
import { HeaderSecondary } from '../../components/HeaderSecondary';
import { CardCenter } from '../../components/CardCenter';
import { SimpleOverviewCard } from '../../components/SimpleOverview';
import { prepareKpiServiceQuery, prepareSystemServiceQuery, useSocket } from '../../contexts/SocketContext';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment/moment';
import { scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { format } from 'd3';
import { Button, ButtonGroup, Dropdown } from '../../components';
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/flatpickr.css";


function MirrorKPIs() {
    const { socket } = useSocket();
    const [historicalData, setHistoricalData] = useState([]);
    const [xTickInterval, setXTickInterval] = useState("day")
    
    
    const [dateLabel, setDateLabel] = useState("Realtime");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const startDateRef = React.useRef();
    const endDateRef = React.useRef();


    const pageConfig = useContext(PageConfigContext)
    useEffect(() => {
        pageConfig.setElementsVisibility({
            navbarHidden: false,
            sidebarHidden: false,
            footerHidden: false
        })
    }, [])


    const getXTickInterval = (data) => {
        const timeRange = data[data.length - 1].date - data[0].date;

        const oneDay = 1000 * 60 * 60 * 24;
        const oneHour = 1000 * 60 * 60;
        const oneMinute = 1000 * 60;
        const granularity = {
            year: 365 * oneDay,
            quarter: 3 * 30 * oneDay,
            month: 30 * oneDay,
            week: 7 * oneDay,
            day: oneDay,
            hour: oneHour,
            minute: oneMinute,
            second: 1000,
        };
        for (const [interval, duration] of Object.entries(granularity)) {
            if (timeRange / duration >= 1) {
                return interval;
            }
        }
        return 'millisecond';
    };
    const formatTimeTick = (tickValue) => {
        const date = new Date(tickValue);
        if (date === "auto" || date === 0) {
            return Date.now()
        }
        console.log(xTickInterval)
        //const xTickInterval = getXTickInterval(data);
        switch (xTickInterval) {
            case 'year':
                return date.getFullYear();
            case 'quarter':
            case 'month':
                return `${date.getDate()}/${date.toLocaleString('default', { month: '2-digit' })}`;
            case 'week':
            case 'day':
                return `${date.getDate()}/${date.toLocaleString('default', { month: '2-digit' })} ${date.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' })}`;
            case 'hour':
                return date.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' });
            case 'minute':
                return date.toLocaleTimeString('default', { hour: 'numeric', minute: '2-digit' });
            default:
                return '';
        }
    };

    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            //refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareKpiServiceQuery("GetCounterAggregation", { application: "restaurant", type: "click", aggregateBy: ["sum", "average"], groupBy: "hour" })).then(res => {
                var timeseries = res.response.map(item => ({
                    ...item,
                    date: moment({ ...item._id, month: item._id.month - 1 }).toDate()
                }))//.filter((a, i) => i < 10)
                //timeseries = [...timeseries, {sum: 1, date: Math.floor(new Date('2050.08.10').getTime() / 1000)
                //}]

                setHistoricalData(timeseries)

                setXTickInterval(getXTickInterval(timeseries))
                console.log(timeseries)
            })
        }
    }

    const icons = {
        specular_clock: "",
        specular_stock_ticker: "",
        specular_daily_sentence: "",
        specular_news: "",
        specular_label: "",
        specular_weather: "",
        specular_todo_list: "",
        specular_weight_tracker: "",
        specular_radio: "",
        specular_youtube_player: "",
        specular_spotify: "",
        specular_flight_tracker: "",
        specular_welcome: "",
        specular_rastaurant: "",
        specular_promotions: ""
    }


    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="KPIs"
                        className="mb-4 mb-lg-5"
                    />
                </Col>
                <Col lg={12}>
                    <HeaderSecondary
                        title="Most Used Apps This Week"
                        className="mb-4 mb-lg-5"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardCenter
                        title="Promotions"
                        content="55 Clicks"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardCenter
                        title="Restaurant"
                        content="2 Orders"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardCenter
                        title="Calendar"
                        content="5 Clicks"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <CardCenter
                        title="Flight Checker"
                        content="3 Updates"
                    />
                </Col>
            </Row>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderSecondary
                        title="Additional Insights"
                        className="mb-4 mb-lg-5"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <SimpleOverviewCard
                        title="Upsell"
                        badgeTitle="average"
                        badgeColor="primary"
                        value="2"
                        valueTitle="purchases per room on weekday"
                        footerTitle="Prev Week"
                        footerTitleClassName="text-success"
                        footerValue="5%"
                        footerIcon="caret-up"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <SimpleOverviewCard
                        title="Restaurant"
                        badgeTitle="average"
                        badgeColor="primary"
                        value="30%"
                        valueTitle="rooms order from the menu"
                        footerTitle="Prev Week"
                        footerTitleClassName="text-success"
                        footerValue="3%"
                        footerIcon="caret-up"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <SimpleOverviewCard
                        title="Flights"
                        badgeTitle="average"
                        badgeColor="primary"
                        value="80%"
                        valueTitle="rooms used flight detector"
                        footerTitle="Prev Week"
                        footerTitleClassName="text-success"
                        footerValue="5%"
                        footerIcon="caret-up"
                    />
                </Col>
                <Col lg={3} md={6}>
                    <SimpleOverviewCard
                        title="Music"
                        badgeTitle="average"
                        badgeColor="primary"
                        value="54"
                        valueTitle="minutes of playing spotify and radio"
                        footerTitle="Prev Week"
                        footerTitleClassName="text-danger"
                        footerValue="15%"
                        footerIcon="caret-down"
                    />
                </Col>
                <Card lg={3} md={6}>
                    <CardBody>
                        <Media>
                            <Media body>
                                <div className="mb-3">
                                    <h5>
                                        <span className="mr-2">
                                            Historical Data
                                        </span>
                                    </h5>
                                    <Badge pill color="primary" className="mr-1">
                                        10 Minutes
                                    </Badge>
                                    <Badge pill color="primary" className="mr-1">
                                        Downlink
                                    </Badge>
                                    <Badge pill color="primary" className="mr-1">
                                        Kbps
                                    </Badge>

                                </div>
                                <Col sm={12}>
                                    <div className="d-flex">
                                        <ButtonToolbar className="ml-auto">
                                            <ButtonGroup className="align-self-start mr-2">
                                                <UncontrolledButtonDropdown className="ml-auto flex-column">
                                                    <DropdownToggle color="link" className="text-left pl-0 text-decoration-none mb-2">
                                                        <i className="fa fa-calendar-o text-body mr-2"></i>
                                                        {dateLabel}
                                                        <i className="fa fa-angle-down text-body ml-2" />
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem header>
                                                            Select Period:
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => setDateLabel("Realtime")} active={dateLabel === "Realtime"}>
                                                            Realtime
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => setDateLabel("Last Month")} active={dateLabel === "Last Month"}>
                                                            Last Month
                                                        </DropdownItem>
                                                        <DropdownItem onClick={() => setDateLabel("Last 3 Months")} active={dateLabel === "Last 3 Months"}>
                                                            Last 3 Months
                                                        </DropdownItem>
                                                        <DropdownItem divider />
                                                        <DropdownItem onClick={() => setDateLabel("Custom...")} active={dateLabel === "Custom..."}>
                                                            Custom...
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </ButtonGroup>
                                            {
                                                dateLabel === "Custom..." &&
                                                <React.Fragment>
                                                    <ButtonGroup className="align-self-start mr-2" onClick={() => { startDateRef.current.flatpickr.isOpen === true ? startDateRef.current.flatpickr.close() : startDateRef.current.flatpickr.open() }}>
                                                        <UncontrolledButtonDropdown className="ml-auto flex-column">
                                                            <DropdownToggle color="link" className="text-left pl-0 text-decoration-none mb-2">
                                                                <i className="fa fa-calendar-o text-body mr-2"></i>
                                                                {startDate === "" ? "Start Date" : (new Date(startDate[0])).toLocaleDateString([], { day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit", hour12: false })}
                                                            </DropdownToggle>
                                                            <Flatpickr
                                                                data-enable-time
                                                                value={startDate}
                                                                onChange={date => {
                                                                    setStartDate(date)
                                                                }}
                                                                ref={startDateRef}
                                                                options={{
                                                                    static: true,
                                                                    time_24hr: true
                                                                }}
                                                                hidden={true}
                                                            />
                                                        </UncontrolledButtonDropdown>
                                                    </ButtonGroup>
                                                    <ButtonGroup className="align-self-start mr-2" onClick={() => { endDateRef.current.flatpickr.isOpen === true ? endDateRef.current.flatpickr.close() : endDateRef.current.flatpickr.open() }}>
                                                        <UncontrolledButtonDropdown className="ml-auto flex-column">
                                                            <DropdownToggle color="link" className="text-left pl-0 text-decoration-none mb-2">
                                                                <i className="fa fa-calendar-o text-body mr-2"></i>
                                                                {endDate === "" ? "End Date" : (new Date(endDate)).toLocaleDateString([], { day: "2-digit", month: "2-digit", hour: "2-digit", minute: "2-digit", hour12: false })}
                                                            </DropdownToggle>
                                                            <Flatpickr
                                                                data-enable-time
                                                                value={endDate}
                                                                onChange={date => {
                                                                    //console.log(date)
                                                                    //console.log((date[0].toLocaleDateString([], { day: "2-digit", month: "2-digit", year: "2-digit", hour: "2-digit", minute: "2-digit", hour12: false})))
                                                                    setEndDate(date)
                                                                }}
                                                                ref={endDateRef}
                                                                options={{
                                                                    static: true,
                                                                    time_24hr: true
                                                                }}
                                                                hidden={true}
                                                            />
                                                        </UncontrolledButtonDropdown>
                                                    </ButtonGroup>
                                                </React.Fragment>
                                            }
                                            <ButtonGroup className="align-self-start">
                                                <Button color="primary" className="mb-2 mr-2 px-3" onClick={() => {/*aplyFilters()*/}}>
                                                    Apply
                                                </Button>
                                            </ButtonGroup>
                                            <ButtonGroup>
                                                <Button
                                                    color="info"
                                                    className="mb-2 text-decoration-none align-self-start"
                                                    onClick={() => { /* resetFilters */}}
                                                >
                                                    Reset
                                                </Button>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </div>
                                </Col>
                                <div>
                                    <LineChart
                                        width={800}
                                        height={300}
                                        data={historicalData}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="date" tickFormatter={formatTimeTick} interval="preserveStartEnd" />
                                        <YAxis dataKey="sum" />
                                        <Tooltip />
                                        <Legend />

                                        <Line type="monotone" dataKey="sum" stroke="#8884d8" activeDot={{ r: 8 }} />
                                    </LineChart>
                                    
                                </div>
                            </Media>
                        </Media>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
}

export default MirrorKPIs;