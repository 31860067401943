import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody, Card, Container, DropdownItem, Modal, ModalBody, Label, ModalFooter } from 'reactstrap';
import { Link } from "react-router-dom";
import { AdvancedTable, ButtonGroup, Button, DeletionModal, Dropdown, FormRow } from '../../components';
import { HeaderMain } from "../../components/HeaderMain";
import { useSocket, prepareSystemServiceQuery, prepareShellQuery } from '../../contexts/SocketContext';
import ShellModal from '../../components/ShellModal';

export function RemoteDevice() {
    const { socket } = useSocket();

    const [ruleValues, setRuleValues] = useState([]);
    const [availableVersions, setAvailableVersions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);
    const [selectedRemote, setSelectedRemote] = useState("");
    const [selectedVersion, setSelectedVersion] = useState(null);

    const [shellModalOpened, setShellModalOpened] = useState(false)


    useEffect(() => {
        refreshValues();
        const interval = setInterval(() => {
            refreshValues();
        }, 30000);
        return () => clearInterval(interval);

    }, [socket]);

    function refreshValues() {
        if (socket !== null && socket.isOpened) {
            setLoading(true)
            Promise.allSettled([socket.sendRequest(prepareSystemServiceQuery("GET", "remote")),
                socket.sendRequest({ request_type: "Remotes", uri: "general" }),
                socket.sendRequest(prepareSystemServiceQuery("GET", "versions"))])
                .then(([remotes, onlineRemotes, versions]) => {
                    if (remotes.value.response !== undefined && onlineRemotes.value !== undefined && onlineRemotes.value.response !== []) {
                        onlineRemotes.value.response.forEach(onlineRemote => {
                            remotes.value.response = remotes.value.response.map(configuredRemote => {
                                if (onlineRemote.remoteId === configuredRemote.serial) {
                                    configuredRemote.online = true;
                                    configuredRemote = { ...configuredRemote, ...onlineRemote.response }
                                }
                                return configuredRemote;
                            })
                        })
                    } else {
                        if (remotes.value.response !== undefined) {
                            remotes.value.response = remotes.value.response.map(configuredRemote => {
                                configuredRemote.online = false;
                                return configuredRemote;
                            })
                        }
                    }
                    //remotes.response[0].online = true
                    setRuleValues(remotes.value.response);
                    if(versions.value.response !== undefined) {
                        setAvailableVersions(versions.value.response);
                        setSelectedVersion(versions.value.response[0]._id)
                    }

                    setLoading(false)
                });
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setRuleValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "remote", { id: cell })).then(() => {
                    refreshValues()
                })
            }
        }
    }

    function toggleUpgradeModal() {
        setUpgradeModalOpened(!upgradeModalOpened);
    }

    function toggleShellModal() {
        setShellModalOpened(!shellModalOpened);
    }

    function setUpgrade() {
        socket.sendRequest({ request_type: "Remotes", uri: "upgrade", data: {remote: selectedRemote, version: selectedVersion} }).then((res) => {
            if(res.response !== undefined) {
                console.log(res.response)
            }
        })
    }

    function setOperation(remote, operation) {
        socket.sendRequest({ request_type: "Remotes", uri: "operation", data: {remote: remote, operation: operation} }).then((res) => {
            if(res.response !== undefined) {
                console.log(res.response)
            }
        })
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="Remote Devices"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <AdvancedTable
                                to="/app/remote/add"
                                loading={loading}
                                columns={[
                                    {
                                        dataField: 'name',
                                        text: 'Name',
                                        sort: true
                                    }, {
                                        dataField: 'activated',
                                        text: 'Activated',
                                        sort: true,
                                        formatter: function name(cell) {
                                            return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <i className="fa fa-fw fa-circle text-danger ml-auto align-self-center"></i>
                                        }
                                    }, {
                                        dataField: 'serial',
                                        text: 'Serial Number',
                                        sort: true
                                    }, {
                                        dataField: 'roomNumber',
                                        text: 'Room Number',
                                        sort: true
                                    }, {
                                        dataField: 'appVersion',
                                        text: 'Version',
                                        sort: true
                                    }, {
                                        dataField: 'online',
                                        text: 'Status',
                                        sort: true,
                                        formatter: function name(cell) {
                                            return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <i className="fa fa-fw fa-circle text-danger ml-auto align-self-center"></i>
                                        }
                                    }, {
                                        dataField: "actions",
                                        text: "Actions",
                                        sort: false,
                                        formatter: function name(cell) {
                                            return (
                                                <ButtonGroup>
                                                    <DeletionModal assetName={cell[0]}
                                                        successFunction={deleteValue}
                                                        successFunctionProp={cell[1] + ""} />
                                                    {
                                                        //cell[2] &&
                                                        <Dropdown toggle="Actions">
                                                            <DropdownItem onClick={() => {setSelectedRemote(cell[0] + ""); setUpgradeModalOpened(true)}}>
                                                                Upgrade Firmware
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => {setSelectedRemote(cell[1] + ""); setShellModalOpened(true);}}>
                                                                Request Shell
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => {setSelectedRemote(cell[1] + ""); setOperation(cell[1], "shutdown");}}>
                                                                Shutdown
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => {setSelectedRemote(cell[1] + ""); setOperation(cell[1], "reboot");}}>
                                                                Reboot
                                                            </DropdownItem>
                                                        </Dropdown>
                                                    }
                                                    <Button tag={Link} to={{
                                                        pathname: '/app/remote/edit/' + cell[0],
                                                        id: cell[0],
                                                    }}>
                                                        Edit
                                                    </Button>
                                                </ButtonGroup>
                                            );
                                        }
                                    }
                                ]}
                                keyField="name"
                                data={
                                    Array.isArray(ruleValues) ? ruleValues.map(returnResource => ({
                                        ...returnResource,
                                        online: returnResource.online !== undefined ? returnResource.online : false,
                                        appVersion: returnResource.appVersion !== undefined ? returnResource.appVersion : '',
                                        actions: [returnResource.name, returnResource._id, returnResource.online]
                                    })) : []
                                }
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {
                upgradeModalOpened &&
                <Modal isOpen={upgradeModalOpened}
                    toggle={toggleUpgradeModal}
                    className="modal-outline-dark">
                    <ModalBody className="text-center px-3">
                        <Container>
                            <Row>
                                <Col className="text-center py-3">
                                    <h2>Version Selection</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormRow>
                                        <Label sm={12}>
                                            Please choose the wanted version
                                        </Label>
                                    </FormRow>
                                    <FormRow sm={12}>
                                        <Dropdown toggle={selectedVersion} style={{width: "100%"}}>
                                            {
                                                availableVersions.map(version => (
                                                    <DropdownItem value key={version.version} onClick={() => setSelectedVersion(version._id)}>
                                                        {`${version.caption} (${version.version})`}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </Dropdown>
                                    </FormRow>
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter className="py-3">
                        <Button color="primary" outline={true} className="mr-2" onClick={() => {
                            setUpgradeModalOpened(false);
                            setUpgrade();
                        }}>
                            Start Upgrade
                        </Button>
                        <Button className="text-info" onClick={() => { setUpgradeModalOpened(false) }}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            }
            <ShellModal remoteId={selectedRemote} showModal={shellModalOpened} changeShowModal={setShellModalOpened} />
        </Container>

    );
}

export default RemoteDevice;